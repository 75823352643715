import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/util/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions, Module, AllModules } from "@ag-grid-enterprise/all-modules";
import { SharedService } from '../../util/shared.service';
import { ReportDataService } from '../report-data-service.service';
import { LoadingService } from 'src/app/util/loading/loading.service';
import { StatementsCellRenderComponent } from '../../reusables/statements/statements-cell-render-component.component';
import { DependenciesModalService } from './../../util/dependenciesmodal/dependenciesmodal.service';
import { Constants } from "src/app/util/constants";
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from 'src/app/util/ws-type';
import { UserVariable } from 'src/app/util/common/user-variable';
import { CommonUtil } from 'src/app/util/common/common-util';

@Component({
	selector: 'app-viewreportdata',
	templateUrl: './viewreportdata.component.html',
	styleUrls: ['./viewreportdata.component.css']
})
export class ViewreportdataComponent implements OnInit {
	
	public gridOptions: GridOptions;
	public gridOptions1: GridOptions;
	public gridOptions2: GridOptions;
	public defaultColDef: any;
	isLoadingData: boolean = true;
	public items: Array<string> = [];
	private gridApi;
	private gridColumnApi;
	columnDefs : any;
	public overlayLoadingTemplate: any;
    public overlayNoRowsTemplate: any;
	private filteredData: any[];
	public modules: Module[] = AllModules;
	rowData: any[] = [];
	public title : string;
	serviceData : any;
	private used : boolean = false;
	private viewWidgetId : string;
	public isMinimized: boolean = false;
	private translated_data: any = null;
	private colName1 : string;
	private colName2 : string;
	private colName3 : string;
	private colName4 : string;
	private colName5 : string;
	private colName6 : string;
	private fieldName1 : string;
	private fieldName2 : string;
	private fieldName3 : string;
	private fieldName4 : string;
	private fieldName5 : string;
	private fieldName6 : string;
	private fieldName7 : string;
	private fieldName8 : string;
	private fieldName9 : string;
	private fieldName10 : string;
	private fieldName11 : string;
	private fieldName12 : string;
	private fieldName13 : string;
	private fieldName14 : string;
	private fieldName15 : string;
	private fieldName16 : string;
	private fieldName17 : string;
	private fieldName18 : string;
	private fieldName19 : string;
	private fieldName20 : string;
	private fieldName21 : string;
	private fieldName22 : string;
	private fieldName23 : string;
	private fieldName24 : string;
	private fieldName25: string;
	private fieldName26 : string;
	private fieldName27 : string;
	private fieldName28 : string;
	private fieldName: String[] = [];
	private colName: String[] = [];
	private NoOfCol : number = 0;
	public divHeight: number = (window.innerHeight) * 4 / 7
	legendTitle: any;
	legend: String[] = [];

	public referencesNew = [];
	private refId: string = "";
	private itemType: string;
	public refClicked: boolean = false;
	rowDataRef: any = [];
	rowDataTagAttr: any = [];
	public columnDefsRef= [];
	public columnDefsTagAttr= [];
    public isShowReferences:boolean = false;
	public loading:boolean = false;
	public hasRefs:boolean = false;
	public loadingTagAttr:boolean = false;
	public hasTagAttreRefs:boolean = false;
	private dependencyType: string;
	public hasList:boolean = true;
	public hasTagAttreList:boolean = true;
	private path: string;
	private file: string;
	private tagAttr: string;
	private tagAttrTag: string;
	private referenceCount: string;
	responseForRef:any = {};
	public hasTagAttr:boolean = false;
	public deprefcountclicked:boolean = false;
	private iceTopicName: string;
	private iceTopicContentName: string;
	public tagAttributeGrid:boolean = false;
	private reusableName : string;
	private referenceType : string;

	constructor(private translate: TranslateService, private alertService: AlertService, private reportDataService: ReportDataService, private loadingService: LoadingService, private dependenciesmodalService: DependenciesModalService, private sharedService: SharedService, private commonUtil: CommonUtil) {
		
		
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
		let params = localStorage.getItem("widgetId");
		let items = params.split(',');
		this.viewWidgetId = items[0];
		this.used = (items[1]=='true');
		this.gridOptions = <GridOptions>{
			context: {
				componentParent: this
			}
		};
		this.gridOptions1 = <GridOptions>{
			context: {
				componentParent: this
			}
		};
		this.gridOptions2 = <GridOptions>{
			context: {
				componentParent: this
			}
		};
		this.defaultColDef = {
			resizable: true,
			sortable: true,
			floatingFilter: true
		}

		this.translate.getTranslation(browserLang).subscribe((res: string) => {
			this.translated_data = res['REPORTS']
			this.setPageParams();
			this.setColumnDefs();
			
			//custom layout templates for loading and empty data sets
			this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading data...</span>';
			this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No rows to show</span>';
			
			});
		
	}

	ngOnInit() {

	}

	private loadData() {
		let params = localStorage.getItem("widgetId");
		let items = params.split(',');
		this.viewWidgetId = items[0];
		this.used = (items[1]=='true');
		console.log("viewWidgetId = " + this.viewWidgetId + " used= "+ this.used);
		this.gridApi.showLoadingOverlay();
		this.reportDataService.getDataListToReport(this.viewWidgetId, this.used)
			.subscribe(data => {
				this.setDataToTable(data.payload);
				},
				error => {
				this.alertService.clear()
				this.alertService.error(error.statusDescription)
				this.rowData = [];
				//this.gridOptions.api.resetRowHeights()
				this.gridOptions.api.sizeColumnsToFit();
			});
	}

	
	onColumnResized(event) {
		if (event.finished) {
		//this.gridApi.resetRowHeights();
		}
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridApi.showLoadingOverlay()
		this.gridColumnApi = params.columnApi;
		// this.gridOptions = { onCellClicked: this.onCellClicked };
		// window.addEventListener("resize", function () {
        //     setTimeout(function () {
        //         params.api.sizeColumnsToFit();
		// 	//	params.api.resetRowHeights();
        //     });
        // });
		window.addEventListener("resize", this.onWindowResize);
		this.loadData();
		setTimeout(function () {
			params.api.sizeColumnsToFit();
		//	params.api.resetRowHeights();
		}, 500);

		params.api.sizeColumnsToFit();
	//	params.api.resetRowHeights();
	}

	private onWindowResize = () => {
   
		setTimeout(() => {
			if (this.gridApi) {
				this.gridApi.sizeColumnsToFit();
			}
		}); 
	};
     
	onReferenceGridReady(params) {

		params.api.sizeColumnsToFit();

	}
	onTagAttrReferenceGridReady(params) {

		params.api.sizeColumnsToFit();

	}
	ngOnDestroy() {

        window.removeEventListener("resize", this.onWindowResize);
		this.gridApi.destroy();
    	this.gridApi = null;
    }
	onCellClicked(event) {
		this.tagAttributeGrid  = false;
		this.columnDefsRef = [

			{ headerName: "File name", field: "file", filter: "agTextColumnFilter" },
			{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
			{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter" },
		];

		
		this.referencesNew = [];
		this.refId = event.data.fieldName1;
		if(this.refId != undefined && this.refId != null && this.refId != ""){
			this.loading =true;
			this.referenceType = event.colDef.headerName;
			if(this.viewWidgetId == 'document_total_reference' && this.used){

				this.itemType = Constants.DEPENDENCY_ITEM_TYPE.DOCUMENT;
				this.reusableName = "Document - "+event.data.fieldName2;
				if(event.column.colId == 'fieldName8'){
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["TopicDependency"]);
				}
				else if(event.column.colId == 'fieldName9') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["TopicMapDependency"]);
					this.columnDefsRef = [

						{ headerName: "Topic Map Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
				}
				else if(event.column.colId == 'fieldName10') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ImageDependency"]);
					this.columnDefsRef = [

						{ headerName: "Image Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter" },
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
						
					];
				}
				else if(event.column.colId == 'fieldName11') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ContentDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
				}
				else if(event.column.colId == 'fieldName12') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["VideoDependency"]);
					this.columnDefsRef = [

						{ headerName: "Video Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
				}
				else if(event.column.colId == 'fieldName13') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ThreeDModelDependency"]);
					this.columnDefsRef = [

						{ headerName: "ThreeD Model Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
				}
				else if(event.column.colId == 'fieldName14') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ProcedureDependency"]);
				}
				else if(event.column.colId == 'fieldName15') {
					this.refClicked = true;
					this.tagAttributeGrid  = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["TagDependency"]);
					this.columnDefsRef = [

						{ headerName: "Tag Number", field: "file", filter: "agTextColumnFilter" },
						
					];
					this.columnDefsTagAttr = [
						{ headerName: "Tag Number", field: "tag", filter: "agTextColumnFilter"},
						{ headerName: "Tag Attribute Name", field: "tagAttr", filter: "agTextColumnFilter"},
					];
				}
				else if(event.column.colId == 'fieldName16') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ICECourseDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
					
				}
				else if(event.column.colId == 'fieldName17') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ICETopicDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "ICE Topic Name", field: "iceTopicName", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter" },
					];
					
				}
				else if(event.column.colId == 'fieldName18') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ICETopicContentDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "ICE Topic Name", field: "iceTopicName", filter: "agTextColumnFilter"},
						{ headerName: "ICE Topic Content Name", field: "iceTopicContentName", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
					
				}

				else if(event.column.colId == 'fieldName19' || event.column.colId == 'fieldName20') {
					this.refClicked = true;
					this.deprefcountclicked = true;
					this.columnDefsRef = [

						{ headerName: "Dependency Type", field: "depType", filter: "agTextColumnFilter"},
						{ headerName: "Dependency Name", field: "depName", filter: "agTextColumnFilter" },
						{ headerName: "Reference Count", field: "refCount", filter: "agTextColumnFilter"},
						
					];
				}

				else {
					this.refClicked = false;
					this.deprefcountclicked = false;
					//do nothing
				}
	
				this.reportDataService.getDependecies(this.refId, this.itemType, "getDependencies");
	
				this.sharedService.setDependencyMessageType(Constants.DEPENDENCY_MESSAGE_TYPE.VIEW);
				this.sharedService.setParent(this);
				this.reportDataService.setCallback(this);
			} 

			if(this.viewWidgetId == 'system_total_reference' && this.used){

				this.itemType = Constants.DEPENDENCY_ITEM_TYPE.SYSTEM;
				this.reusableName = "System - "+event.data.fieldName3;
				if(event.column.colId == 'fieldName5') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["TopicDependency"]);					

				}
				else if(event.column.colId == 'fieldName6') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["TopicMapDependency"]);	
					this.columnDefsRef = [

						{ headerName: "Topic Map Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];				

				}
				else if(event.column.colId == 'fieldName7') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ImageDependency"]);
					this.columnDefsRef = [

						{ headerName: "Image Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
						
					];
					
				}
				else if(event.column.colId == 'fieldName8') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ContentDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
					
				}
				else if(event.column.colId == 'fieldName9') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["VideoDependency"]);
					this.columnDefsRef = [

						{ headerName: "Video Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
					
				}
				else if(event.column.colId == 'fieldName10') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ThreeDModelDependency"]);
					this.columnDefsRef = [

						{ headerName: "ThreeD Model Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
					
				}
				else if(event.column.colId == 'fieldName11') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ProcedureDependency"]);
					
				}
				else if(event.column.colId == 'fieldName12') {
					this.refClicked = true;
					this.tagAttributeGrid  = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["TagDependency"]);
					this.columnDefsRef = [

						{ headerName: "Tag Number", field: "file", filter: "agTextColumnFilter"},
						
					];
					this.columnDefsTagAttr = [
						{ headerName: "Tag Number", field: "tag", filter: "agTextColumnFilter"},
						{ headerName: "Tag Attribute Name", field: "tagAttr", filter: "agTextColumnFilter"},
					];
					
				}
				else if(event.column.colId == 'fieldName13') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["CheckSheetDependency"]);
					this.columnDefsRef = [

						{ headerName: "Checksheet", field: "file", filter: "agTextColumnFilter"},

					];
					
				}

				else if(event.column.colId == 'fieldName14') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ICECourseDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
					
				}
				else if(event.column.colId == 'fieldName15') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ICETopicDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "ICE Topic Name", field: "iceTopicName", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
					
				}
				else if(event.column.colId == 'fieldName16') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ICETopicContentDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "ICE Topic Name", field: "iceTopicName", filter: "agTextColumnFilter"},
						{ headerName: "ICE Topic Content Name", field: "iceTopicContentName", filter: "agTextColumnFilter" },
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
					
				}
				else if(event.column.colId == 'fieldName17') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["SystemDependency"]);
					this.columnDefsRef = [

						{ headerName: "System", field: "file", filter: "agTextColumnFilter"},

					];
					
				}
				else if(event.column.colId == 'fieldName18') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["PunchlistDependency"]);
					this.columnDefsRef = [

						{ headerName: "Punchlist", field: "file", filter: "agTextColumnFilter"},

					];
					
				}
				else if(event.column.colId == 'fieldName19') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["MCCDependency"]);
					this.columnDefsRef = [

						{ headerName: "Mechanical Completion Certificate", field: "file", filter: "agTextColumnFilter"},

					];
					
				}
				else if(event.column.colId == 'fieldName20') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["SCCDependency"]);
					this.columnDefsRef = [

						{ headerName: "Static Completion Certificate", field: "file", filter: "agTextColumnFilter"},

					];
					
				}
				else if(event.column.colId == 'fieldName21') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["CCCDependency"]);
					this.columnDefsRef = [

						{ headerName: "Comm Completion Certificate", field: "file", filter: "agTextColumnFilter"},

					];
					
				}
				else if(event.column.colId == 'fieldName22') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["STNDependency"]);
					this.columnDefsRef = [

						{ headerName: "System Turnover Notice", field: "file", filter: "agTextColumnFilter"},

					];
					
				}
				else if(event.column.colId == 'fieldName23') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["SHCDependency"]);
					this.columnDefsRef = [

						{ headerName: "System Handover Certificate", field: "file", filter: "agTextColumnFilter"},

					];
					
				}
				else if(event.column.colId == 'fieldName24') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["SCSDependency"]);
					this.columnDefsRef = [

						{ headerName: "System Completion Status", field: "file", filter: "agTextColumnFilter"},

					];
					
				}
				else if(event.column.colId == 'fieldName25') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["TopicMapOutputFacilityDependency"]);
					this.columnDefsRef = [

						{ headerName: "Document", field: "file", filter: "agTextColumnFilter"},

					];
					
				}
				else if(event.column.colId == 'fieldName26' || event.column.colId == 'fieldName27') {
					this.refClicked = true;
					this.deprefcountclicked = true;
					this.columnDefsRef = [

						{ headerName: "Dependency Type", field: "depType", filter: "agTextColumnFilter"},
						{ headerName: "Dependency Name", field: "depName", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refCount", filter: "agTextColumnFilter"},
						
					];
					
				}
				else {
					this.deprefcountclicked = false;
					//do nothing
				}
	
				this.reportDataService.getDependecies(this.refId, this.itemType, "getDependencies");
	
				this.sharedService.setDependencyMessageType(Constants.DEPENDENCY_MESSAGE_TYPE.VIEW);
				this.sharedService.setParent(this);
				this.reportDataService.setCallback(this);
			}

			else if(this.viewWidgetId == 'statements_total_reference' && this.used){

				this.itemType = Constants.DEPENDENCY_ITEM_TYPE.STATEMENT;
				this.reusableName = "Statement - "+event.data.fieldName2;
				if(event.column.colId == 'fieldName5'){
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["TopicDependency"]);
				}
				else if(event.column.colId == 'fieldName6') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ICECourseDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter" },
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter" },
					];
					
				}
				else if(event.column.colId == 'fieldName7') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ICETopicDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter" },
						{ headerName: "ICE Topic Name", field: "iceTopicName", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
					
				}
				else if(event.column.colId == 'fieldName8') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ICETopicContentDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "ICE Topic Name", field: "iceTopicName", filter: "agTextColumnFilter"},
						{ headerName: "ICE Topic Content Name", field: "iceTopicContentName", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter" },
					];
					
				}
				else if(event.column.colId == 'fieldName9') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ProcedureDependency"]);
				}
				else if(event.column.colId == 'fieldName10' || event.column.colId == 'fieldName11') {
					this.refClicked = true;
					this.deprefcountclicked = true;
					this.columnDefsRef = [

						{ headerName: "Dependency Type", field: "depType", filter: "agTextColumnFilter"},
						{ headerName: "Dependency Name", field: "depName", filter: "agTextColumnFilter" },
						{ headerName: "Reference Count", field: "refCount", filter: "agTextColumnFilter"},
						
					];
				}

				else {
					this.deprefcountclicked = false;
					//do nothing
				}
	
				this.reportDataService.getDependecies(this.refId, this.itemType, "getDependencies");
	
				this.sharedService.setDependencyMessageType(Constants.DEPENDENCY_MESSAGE_TYPE.VIEW);
				this.sharedService.setParent(this);
				this.reportDataService.setCallback(this);
			} 
			else if(this.viewWidgetId == 'component_total_reference' && this.used){

				this.itemType = Constants.DEPENDENCY_ITEM_TYPE.COMPONENT;
				this.reusableName = "Component - "+event.data.fieldName2;
				if(event.column.colId == 'fieldName5'){
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["TopicDependency"]);
				}
				else if(event.column.colId == 'fieldName6') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["TopicMapDependency"]);
					this.columnDefsRef = [

						{ headerName: "Topic Map Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
				}
				else if(event.column.colId == 'fieldName7') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ImageDependency"]);
					this.columnDefsRef = [

						{ headerName: "Image Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
						
					];
				}
				else if(event.column.colId == 'fieldName8') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ContentDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter" },
					];
				}
				else if(event.column.colId == 'fieldName9') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["VideoDependency"]);
					this.columnDefsRef = [

						{ headerName: "Video Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
				}
				else if(event.column.colId == 'fieldName10') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ThreeDModelDependency"]);
					this.columnDefsRef = [

						{ headerName: "ThreeD Model Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
				}
				else if(event.column.colId == 'fieldName11') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ProcedureDependency"]);
				}
				else if(event.column.colId == 'fieldName12') {
					this.refClicked = true;
					this.tagAttributeGrid  = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["TagDependency"]);

					this.columnDefsTagAttr = [
						{ headerName: "Tag Number", field: "tag", filter: "agTextColumnFilter"},
						{ headerName: "Tag Attribute Name", field: "tagAttr", filter: "agTextColumnFilter"},
					];
				}
				else if(event.column.colId == 'fieldName13') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ICECourseDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
					
				}
				else if(event.column.colId == 'fieldName14') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ICETopicDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter" },
						{ headerName: "ICE Topic Name", field: "iceTopicName", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
					
				}
				else if(event.column.colId == 'fieldName15') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ICETopicContentDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "ICE Topic Name", field: "iceTopicName", filter: "agTextColumnFilter"},
						{ headerName: "ICE Topic Content Name", field: "iceTopicContentName", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
					
				}
	
				else if(event.column.colId == 'fieldName16' || event.column.colId == 'fieldName17') {
					this.refClicked = true;
					this.deprefcountclicked = true;
					this.columnDefsRef = [

						{ headerName: "Dependency Type", field: "depType", filter: "agTextColumnFilter"},
						{ headerName: "Dependency Name", field: "depName", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refCount", filter: "agTextColumnFilter"},
						
					];
				}

				else {
					this.deprefcountclicked = false;
					//do nothing
				}
	
				this.reportDataService.getDependecies(this.refId, this.itemType, "getDependencies");
	
				this.sharedService.setDependencyMessageType(Constants.DEPENDENCY_MESSAGE_TYPE.VIEW);
				this.sharedService.setParent(this);
				this.reportDataService.setCallback(this);
			} 
			else if(this.viewWidgetId == 'facility_total_reference' && this.used){

				this.itemType = Constants.DEPENDENCY_ITEM_TYPE.FACILITY;
				this.reusableName = "Facility - "+event.data.fieldName2;
				if(event.column.colId == 'fieldName5'){
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["TopicDependency"]);
				}
				else if(event.column.colId == 'fieldName6') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ProcedureDependency"]);
				}
				else if(event.column.colId == 'fieldName7') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["TagDependency"]);
					this.columnDefsRef = [

						{ headerName: "Tag Number", field: "file", filter: "agTextColumnFilter" },
						
					];

				}
				else if(event.column.colId == 'fieldName8') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["SystemDependency"]);
					this.columnDefsRef = [

						{ headerName: "System", field: "file" , filter: "agTextColumnFilter"},
						
					];
				}
				else if(event.column.colId == 'fieldName9') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["DocumentDependency"]);
					this.columnDefsRef = [

						{ headerName: "Document", field: "file", filter: "agTextColumnFilter"},
						
					];
				}
				
				else if(event.column.colId == 'fieldName10') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ICECourseDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
					
				}
				else if(event.column.colId == 'fieldName11') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ICETopicDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "ICE Topic Name", field: "iceTopicName", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
					
				}
				else if(event.column.colId == 'fieldName12') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ICETopicContentDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter" },
						{ headerName: "ICE Topic Name", field: "iceTopicName", filter: "agTextColumnFilter" },
						{ headerName: "ICE Topic Content Name", field: "iceTopicContentName", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter" },
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter"},
					];
					
				}
				else if(event.column.colId == 'fieldName13') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["TopicMapOutputDependency"]);
					this.columnDefsRef = [

						{ headerName: "Document", field: "file", filter: "agTextColumnFilter"},

					];
					
				}

				else if(event.column.colId == 'fieldName14' || event.column.colId == 'fieldName15') {
					this.refClicked = true;
					this.deprefcountclicked = true;
					this.columnDefsRef = [

						{ headerName: "Dependency Type", field: "depType", filter: "agTextColumnFilter" },
						{ headerName: "Dependency Name", field: "depName", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refCount", filter: "agTextColumnFilter"},
						
					];
				}

				else {
					this.deprefcountclicked = false;
					//do nothing
				}
	
				this.reportDataService.getDependecies(this.refId, this.itemType, "getDependencies");
	
				this.sharedService.setDependencyMessageType(Constants.DEPENDENCY_MESSAGE_TYPE.VIEW);
				this.sharedService.setParent(this);
				this.reportDataService.setCallback(this);
			}

			else if(this.viewWidgetId == 'tags_total_tag_reference' && this.used){
				this.reusableName = "Tag - "+event.data.fieldName2;
				if(event.column.colId == 'fieldName7'){
					this.refClicked = true;
					this.sharedService.setFileType('TagNumber');
					this.itemType = this.sharedService.getFileType();
					this.columnDefsRef = [

						{ headerName: "Dependency Type", field: "depType", filter: "agTextColumnFilter"},
						{ headerName: "Dependency Name", field: "depName", filter: "agTextColumnFilter"},
						{ headerName: "Folder Path", field: "path", filter: "agTextColumnFilter" },
						{ headerName: "Reference Count", field: "refCount", filter: "agTextColumnFilter" },
						
					];				
				}
				else if(event.column.colId == 'fieldName8'){
					this.refClicked = true;
					this.sharedService.setFileType('TagDescription');	
					this.itemType = this.sharedService.getFileType();
					this.columnDefsRef = [

						{ headerName: "Dependency Type", field: "depType", filter: "agTextColumnFilter"},
						{ headerName: "Dependency Name", field: "depName", filter: "agTextColumnFilter"},
						{ headerName: "Folder Path", field: "path", filter: "agTextColumnFilter" },
						{ headerName: "Reference Count", field: "refCount", filter: "agTextColumnFilter"},
						
					];				
				}
				else if(event.column.colId == 'fieldName9'){
					this.refClicked = true;
					this.sharedService.setFileType('TagSystem');
					this.itemType = this.sharedService.getFileType();
					this.columnDefsRef = [

						{ headerName: "Dependency Type", field: "depType", filter: "agTextColumnFilter"},
						{ headerName: "Dependency Name", field: "depName", filter: "agTextColumnFilter"},
						{ headerName: "Folder Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refCount", filter: "agTextColumnFilter"},
						
					];					
				}
				else if(event.column.colId == 'fieldName10'){
					this.refClicked = true;
					this.sharedService.setFileType('TagDocument');
					this.itemType = this.sharedService.getFileType();
					this.columnDefsRef = [

						{ headerName: "Dependency Type", field: "depType", filter: "agTextColumnFilter"},
						{ headerName: "Dependency Name", field: "depName", filter: "agTextColumnFilter"},
						{ headerName: "Folder Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refCount", filter: "agTextColumnFilter"},
						
					];
						
				}
				else if(event.column.colId == 'fieldName11'){
					this.refClicked = true;
					this.sharedService.setFileType('TagAttribute');	
					this.itemType = this.sharedService.getFileType();
					this.columnDefsRef = [

						{ headerName: "Dependency Type", field: "depType", filter: "agTextColumnFilter" },
						{ headerName: "Dependency Name", field: "depName", filter: "agTextColumnFilter"},
						{ headerName: "Folder Path", field: "path", filter: "agTextColumnFilter" },
						{ headerName: "Reference Count", field: "refCount", filter: "agTextColumnFilter"},
						
					];					
				}
				else if(event.column.colId == 'fieldName12' || event.column.colId == 'fieldName13'){
					this.refClicked = true;
					this.itemType = Constants.DEPENDENCY_ITEM_TYPE.TAG;
					this.columnDefsRef = [

						{ headerName: "Dependency Type", field: "depType", filter: "agTextColumnFilter"},
						{ headerName: "Dependency Name", field: "depName", filter: "agTextColumnFilter"},
						{ headerName: "Folder Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refCount", filter: "agTextColumnFilter"},
						
					];
				}
				else{
					this.itemType = Constants.DEPENDENCY_ITEM_TYPE.TAG;
					//do nothing
				}


				let token = UserVariable.getUserToken();
				if(!this.commonUtil.validateToken(token)){
						return;
				}
				var request: any = { "itemId": this.refId, "itemType": this.itemType, "action": "getDependencies", "userToken": token };
				this.reportDataService.getTagDependenciesByTag(JSON.stringify(request), this);

				this.sharedService.setDependencyMessageType(Constants.DEPENDENCY_MESSAGE_TYPE.VIEW);
				this.sharedService.setParent(this);
				this.reportDataService.setCallback(this);


			}

			else if(this.viewWidgetId == 'tags_total_tag_reference_ref_view'){

				this.itemType = Constants.DEPENDENCY_ITEM_TYPE.TAG;
				this.reusableName = "Tag - "+event.data.fieldName2;
				if(event.column.colId == 'fieldName4') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["TopicDependency"]);
					
				}
				else if(event.column.colId == 'fieldName5') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ProcedureDependency"]);
					
				}
				else if(event.column.colId == 'fieldName6') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ICECourseDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter" },
					];
					
				}
				else if(event.column.colId == 'fieldName7' ) {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ICETopicDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "ICE Topic Name", field: "iceTopicName", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter" },
					];
					
				}
				else if(event.column.colId == 'fieldName8') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["ICETopicContentDependency"]);
					this.columnDefsRef = [

						{ headerName: "File Name", field: "file", filter: "agTextColumnFilter"},
						{ headerName: "ICE Topic Name", field: "iceTopicName", filter: "agTextColumnFilter" },
						{ headerName: "ICE Topic Content Name", field: "iceTopicContentName", filter: "agTextColumnFilter"},
						{ headerName: "Path", field: "path", filter: "agTextColumnFilter" },
						{ headerName: "Reference Count", field: "refcount", filter: "agTextColumnFilter" },
					];
					
				}
				else if(event.column.colId == 'fieldName9') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["AssetParentDependency"]);
					this.columnDefsRef = [

						{ headerName: "Asset Parent", field: "file", filter: "agTextColumnFilter"},

					];
					
				}
				else if(event.column.colId == 'fieldName10') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["AssetAttrDependency"]);
					this.columnDefsRef = [

						{ headerName: "Asset Attribute", field: "file", filter: "agTextColumnFilter"},

					];
					
				}
				else if(event.column.colId == 'fieldName11') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["CheckSheetDependency"]);
					this.columnDefsRef = [

						{ headerName: "Checksheet", field: "file", filter: "agTextColumnFilter"},

					];
					
				}
				else if(event.column.colId == 'fieldName12') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["TagDependency"]);
					this.columnDefsRef = [

						{ headerName: "Tag Number", field: "file", filter: "agTextColumnFilter"},

					];
					
				}
				else if(event.column.colId == 'fieldName13') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["CommWorkItemDependency"]);
					this.columnDefsRef = [

						{ headerName: "Work Item", field: "file", filter: "agTextColumnFilter"},

					];
					
				}
				else if(event.column.colId == 'fieldName14') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["PunchlistDependency"]);
					this.columnDefsRef = [

						{ headerName: "Punchlist", field: "file", filter: "agTextColumnFilter"},

					];
					
				}
				else if(event.column.colId == 'fieldName15') {
					this.refClicked = true;
					this.deprefcountclicked = false;
					this.sharedService.setDependencyTypeList(["IRNDependency"]);
					this.columnDefsRef = [

						{ headerName: "IRN", field: "file", filter: "agTextColumnFilter"},

					];
					
				}	
				else if(event.column.colId == 'fieldName16' || event.column.colId == 'fieldName17') {
					this.refClicked = true;
					this.deprefcountclicked = true;
					this.columnDefsRef = [

						{ headerName: "Dependency Type", field: "depType", filter: "agTextColumnFilter"},
						{ headerName: "Dependency Name", field: "depName", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refCount", filter: "agTextColumnFilter"},
						
					];
				}
				else {
					this.deprefcountclicked = false;
					//do nothing
				}
				let token = UserVariable.getUserToken();
				if(!this.commonUtil.validateToken(token)){
						return;
				}
				var request: any = { "itemId": this.refId, "itemType": this.itemType, "action": "getDependencies", "userToken": token };
				this.reportDataService.getTagDependenciesByTag(JSON.stringify(request), this);

				this.sharedService.setDependencyMessageType(Constants.DEPENDENCY_MESSAGE_TYPE.VIEW);
				this.sharedService.setParent(this);
				this.reportDataService.setCallback(this);
			}
			else if(this.viewWidgetId == 'system_total_reference_sys_view'){
				this.reusableName = "System - "+event.data.fieldName3;
				if(event.column.colId == 'fieldName4'){
					this.refClicked = true;
					this.sharedService.setFileType('SystemNo');
					this.itemType = this.sharedService.getFileType();
					this.columnDefsRef = [

						{ headerName: "Dependency Type", field: "depType", filter: "agTextColumnFilter" },
						{ headerName: "Dependency Name", field: "depName", filter: "agTextColumnFilter"},
						{ headerName: "Folder Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refCount", filter: "agTextColumnFilter"},
						
					];				
				}
				else if(event.column.colId == 'fieldName5'){
					this.refClicked = true;
					this.sharedService.setFileType('SystemName');	
					this.itemType = this.sharedService.getFileType();
					this.columnDefsRef = [

						{ headerName: "Dependency Type", field: "depType", filter: "agTextColumnFilter" },
						{ headerName: "Dependency Name", field: "depName", filter: "agTextColumnFilter" },
						{ headerName: "Folder Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refCount", filter: "agTextColumnFilter" },
						
					];				
				}

				else if(event.column.colId == 'fieldName6' || event.column.colId == 'fieldName7'){
					this.refClicked = true;
					this.sharedService.setFileType('SystemView');	
					this.itemType = this.sharedService.getFileType();
					this.columnDefsRef = [

						{ headerName: "Dependency Type", field: "depType", filter: "agTextColumnFilter"},
						{ headerName: "Dependency Name", field: "depName", filter: "agTextColumnFilter" },
						{ headerName: "Folder Path", field: "path", filter: "agTextColumnFilter"},
						{ headerName: "Reference Count", field: "refCount", filter: "agTextColumnFilter"},
						
					];
				}
				else{
					//do nothing
				}


				this.reportDataService.getDependecies(this.refId, this.itemType, "getDependencies");
	
				this.sharedService.setDependencyMessageType(Constants.DEPENDENCY_MESSAGE_TYPE.VIEW);
				this.sharedService.setParent(this);
				this.reportDataService.setCallback(this);


			}
		} else{

			this.loading =false;
			this.alertService.error('Reusable Id Not Available');
		}
		
	}

	filterDependencies(dependencies: any): any{
        let dependencyTypeList = this.sharedService.getDependencyTypeList();
		this.dependencyType = dependencyTypeList[0];
        this.sharedService.resetDependencyTypeList();
        if(dependencyTypeList == null){
            return dependencies;
        }
        else{
            let newDependencies:any = {};
            
            dependencyTypeList.forEach(dependencyType => {
                newDependencies[dependencyType] = dependencies[dependencyType];
                if(newDependencies[dependencyType] != undefined && newDependencies[dependencyType] != ""){
                    newDependencies["HasDependencies"] = 1;
                }
            });
            
            return newDependencies;
        }

    }

	onSuccess(data: WsResponse, serviceType: WsType): void {
		if (serviceType == WsType.ITEM_DEPENDENCY_LIST) {
            this.loadingService.hideLoading();
            let response = (this.itemType == "DocumentType" && this.sharedService.getLoadedComponent() == "manageattributes") ? JSON.parse(JSON.stringify(data.payload)) : JSON.parse(data.payload);
			this.responseForRef = response;
			if(this.viewWidgetId == 'system_total_reference_sys_view'){

				this.isShowReferences = true;
				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;
				this.hasRefs = true;
				this.loading = false;

				this.rowDataRef = [];
				var deptype = "";
				var depname = "";
				var refcount = "";
				var path = "";

				if (this.responseForRef.TopicDependency != undefined) {
					if (this.responseForRef.TopicDependency != '') {
						if (this.responseForRef.TopicDependency.Topics.dependencyCount != 1) {
							let refList = this.responseForRef.TopicDependency.Topics.Topic;
							for(let i = 0; i < refList.length; i++){
								
								deptype = "Topic Dependency";						
								depname = refList[i].content;
								refcount = refList[i].referenceCount;
								path = refList[i].topicPath;
	
								this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount, path:path});
	
							}
							
						} else {
							deptype = "Topic Dependency";
							depname = this.responseForRef.TopicDependency.Topics.Topic.content;
							refcount = this.responseForRef.TopicDependency.Topics.Topic.referenceCount;
							path = this.responseForRef.TopicDependency.Topics.Topic.topicPath;
							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount, path: path});
	
						}					
						var deptype = "";
						var depname = "";
						var refcount = "";
						var path = "";
					}}
					if (this.responseForRef.ProcedureDependency != undefined) {
					if (this.responseForRef.ProcedureDependency != '') {
						if (this.responseForRef.ProcedureDependency.Procedures.dependencyCount != 1) {
							let refList = this.responseForRef.ProcedureDependency.Procedures.Procedure;
							for(let i = 0; i < refList.length; i++){
								
								deptype = "Procedure Dependency";						
								depname = refList[i].content;
								refcount = refList[i].referenceCount;
								path = refList[i].procPath;
								this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount, path: path});
	
							}
							
						} else {
							deptype = "Procedure Dependency";
							depname = this.responseForRef.ProcedureDependency.Procedures.Procedure.content;
							refcount = this.responseForRef.ProcedureDependency.Procedures.Procedure.referenceCount;
							path = this.responseForRef.ProcedureDependency.Procedures.Procedure.procPath;
							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount, path: path});
	
						}
						var deptype = "";
						var depname = "";
						var refcount = "";
						var path = "";
					}}
				if (this.responseForRef.ICECourseDependency != undefined) {
					if (this.responseForRef.ICECourseDependency != '') {
						if (this.responseForRef.ICECourseDependency.ICECourses.dependencyCount != 1) {
							let refList = this.responseForRef.ICECourseDependency.ICECourses.ICECourse;
							for(let i = 0; i < refList.length; i++){
								
								deptype = "ICE Course Dependency";	
								var html = refList[i].content ;
								var div = document.createElement("div");
								div.innerHTML = html;
								refList[i].content = div.textContent || div.innerText || "";
								//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;					
								depname = refList[i].FileName;
								refcount = refList[i].referenceCount;
								path = refList[i].iceCoursePath;
	
								this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount, path: path});
	
							}
							
						} else {
							deptype = "ICE Course Dependency";
							var html = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content ;
							var div = document.createElement("div");
							div.innerHTML = html;
							this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content = div.textContent || div.innerText || "";
							depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.FileName;
							//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;
							refcount = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.referenceCount;
							path = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.iceCoursePath;
	
							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount, path: path});
	
						}
						var deptype = "";
						var depname = "";
						var refcount = "";
						var path = "";
					}}
					if (this.responseForRef.ICETopicDependency != undefined) {
					if (this.responseForRef.ICETopicDependency != '') {
						if (this.responseForRef.ICETopicDependency.ICETopics.dependencyCount != 1) {
							let refList = this.responseForRef.ICETopicDependency.ICETopics.ICETopic;
							for(let i = 0; i < refList.length; i++){
								
								deptype = "ICE Topic Dependency";	
								var html = refList[i].content ;
								var div = document.createElement("div");
								div.innerHTML = html;
								refList[i].content = div.textContent || div.innerText || "";
								//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;					
								depname = refList[i].FileName;
								refcount = refList[i].referenceCount;
								path = refList[i].iceTopicPath;
	
								this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount, path: path});
	
							}
							
						} else {
							deptype = "ICE Topic Dependency";
							var html = this.responseForRef.ICETopicDependency.ICETopics.ICETopic.content ;
							var div = document.createElement("div");
							div.innerHTML = html;
							this.responseForRef.ICETopicDependency.ICETopics.ICETopic.content = div.textContent || div.innerText || "";
							depname = this.responseForRef.ICETopicDependency.ICETopics.ICETopic.FileName;
							//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;
							refcount = this.responseForRef.ICETopicDependency.ICETopics.ICETopic.referenceCount;
							path = this.responseForRef.ICETopicDependency.ICETopics.ICETopic.iceTopicPath;
							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount, path: path});
	
						}
						var deptype = "";
						var depname = "";
						var refcount = "";
						var path = "";
					}}
					if (this.responseForRef.ICETopicContentDependency != undefined) {
					if (this.responseForRef.ICETopicContentDependency != '') {
						if (this.responseForRef.ICETopicContentDependency.ICETopicContents.dependencyCount != 1) {
							let refList = this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent;
							for(let i = 0; i < refList.length; i++){
								
								deptype = "ICE Topic Content Dependency";	
								var html = refList[i].content ;
								var div = document.createElement("div");
								div.innerHTML = html;
								refList[i].content = div.textContent || div.innerText || "";
								//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;					
								depname = refList[i].FileName;
								refcount = refList[i].referenceCount;
								path = refList[i].iceTopicContentPath;
	
								this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount, path : path});
	
							}
							
						} else {
							deptype = "ICE Topic Content Dependency";
							var html = this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent.content ;
							var div = document.createElement("div");
							div.innerHTML = html;
							this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent.content = div.textContent || div.innerText || "";
							depname = this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent.FileName;
							//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;
							refcount = this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent.referenceCount;
							path =  this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent.iceTopicContentPath;
							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount, path: path});
	
						}
						var deptype = "";
						var depname = "";
						var refcount = "";
						var path = "";
					}}
					this.gridOptions1.api.sizeColumnsToFit();

			}
			else{
			if(!this.deprefcountclicked){
            response = this.filterDependencies(response);
			console.log(JSON.stringify(response));

			if(this.dependencyType == "TopicDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.TopicDependency == ''){
					this.hasRefs = false;
               		this.loading = false;

				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.TopicDependency.Topics.dependencyCount == 1){
						this.hasList = false;
						this.path = response.TopicDependency.Topics.Topic.topicPath;
						this.file = response.TopicDependency.Topics.Topic.content;
						this.referenceCount = response.TopicDependency.Topics.Topic.referenceCount;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.TopicDependency.Topics.Topic;
					}
				}
								
			} else if(this.dependencyType == "ProcedureDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.ProcedureDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.ProcedureDependency.Procedures.dependencyCount == 1){
						this.hasList = false;
						this.path = response.ProcedureDependency.Procedures.Procedure.procPath;
						this.file = response.ProcedureDependency.Procedures.Procedure.content;
						this.referenceCount = response.ProcedureDependency.Procedures.Procedure.referenceCount;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.ProcedureDependency.Procedures.Procedure;
					}

			}

			} else if(this.dependencyType == "TagDependency" ){

				if(response.TagDependency != undefined){
				if(response.TagDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.TagDependency.Tags.dependencyCount == 1){
						this.hasList = false;
						this.file = response.TagDependency.Tags.Tag.tagNo;
					
					}else {
						this.hasList = true;

						this.referencesNew = response.TagDependency.Tags.Tag;
					}
				}}
				else{
					this.hasRefs = false;
               		this.loading = false;
				}

				if(this.responseForRef.TagAttributeDependency != undefined){
				if(this.responseForRef.TagAttributeDependency != ''){
					this.hasTagAttreRefs = true;
					this.loadingTagAttr = false;
					this.hasTagAttr = true;

					if(this.responseForRef.TagAttributeDependency.TagAttribute.dependencyCount == 1){
						this.hasTagAttreList = false;
						this.tagAttr = this.responseForRef.TagAttributeDependency.TagAttribute.TagAttribute.dependencyName;
						this.tagAttrTag = this.responseForRef.TagAttributeDependency.TagAttribute.TagAttribute.tagNo;
	
					}
					else {
						this.hasTagAttreList = true;
					}
				}else {
					this.hasTagAttreRefs = false;
					this.loadingTagAttr = false;
					this.hasTagAttr = false;
				}}

			}
			else if(this.dependencyType == "CheckSheetDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.CheckSheetDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.CheckSheetDependency.CheckSheets.dependencyCount == 1){
						this.hasList = false;						
						this.file = response.CheckSheetDependency.CheckSheets.CheckSheet.checkSheetName;					
					}
					else {
						this.hasList = true;
						this.referencesNew = response.CheckSheetDependency.CheckSheets.CheckSheet;
					}

			}

			}
			else if(this.dependencyType == "ImageDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.ImageDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.ImageDependency.Images.dependencyCount == 1){
						this.hasList = false;
						this.file = response.ImageDependency.Images.Image.ImageName;
						this.path = response.ImageDependency.Images.Image.FilePath;
						this.referenceCount = response.ImageDependency.Images.Image.refcount;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.ImageDependency.Images.Image;
					}

			}

			}
			else if(this.dependencyType == "TopicMapDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.TopicMapDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.TopicMapDependency.TopicMaps.dependencyCount == 1){
						this.hasList = false;
						this.file = response.TopicMapDependency.TopicMaps.TopicMap.topicMapName;
						this.path = response.TopicMapDependency.TopicMaps.TopicMap.FilePath;
						this.referenceCount = response.TopicMapDependency.TopicMaps.TopicMap.refcount;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.TopicMapDependency.TopicMaps.TopicMap;
					}

			}

			}
			else if(this.dependencyType == "ContentDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.ContentDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.ContentDependency.ContentItems.dependencyCount == 1){
						this.hasList = false;
						this.file = response.ContentDependency.ContentItems.ContentItem.ContentName;
						this.path = response.ContentDependency.ContentItems.ContentItem.FilePath;
						this.referenceCount = response.ContentDependency.ContentItems.ContentItem.refcount;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.ContentDependency.ContentItems.ContentItem;
					}

			}

			}
			else if(this.dependencyType == "VideoDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.VideoDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.VideoDependency.Videos.dependencyCount == 1){
						this.hasList = false;
						this.file = response.VideoDependency.Videos.Video.VideoName;
						this.path = response.VideoDependency.Videos.Video.FilePath;
						this.referenceCount = response.VideoDependency.Videos.Video.refcount;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.VideoDependency.Videos.Video;
					}

			}

			}
			else if(this.dependencyType == "ThreeDModelDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.ThreeDModelDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.ThreeDModelDependency.ThreeDModels.dependencyCount == 1){
						this.hasList = false;
						this.file = response.ThreeDModelDependency.ThreeDModels.ThreeDModel.ThreeDModelName;
						this.path = response.ThreeDModelDependency.ThreeDModels.ThreeDModel.FilePath;
						this.referenceCount = response.ThreeDModelDependency.ThreeDModels.ThreeDModel.refcount;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.ThreeDModelDependency.ThreeDModels.ThreeDModel;
					}

			}

			}

			else if(this.dependencyType == "ICECourseDependency" ){
				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.ICECourseDependency == ''){
					this.hasRefs = false;
               		this.loading = false;

				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.ICECourseDependency.ICECourses.dependencyCount == 1){
						this.hasList = false;
						this.path = response.ICECourseDependency.ICECourses.ICECourse.iceCoursePath;

						var html = response.ICECourseDependency.ICECourses.ICECourse.content ;
            			var div = document.createElement("div");
						div.innerHTML = html;
						response.ICECourseDependency.ICECourses.ICECourse.content = div.textContent || div.innerText || "";

						this.file = response.ICECourseDependency.ICECourses.ICECourse.FileName;					
						this.referenceCount = response.ICECourseDependency.ICECourses.ICECourse.referenceCount;
					}
					else {
						this.hasList = true;

						this.referencesNew = response.ICECourseDependency.ICECourses.ICECourse;
					}
				}
			}
			else if(this.dependencyType == "ICETopicDependency" ){
				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.ICETopicDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.ICETopicDependency.ICETopics.dependencyCount == 1){
						this.hasList = false;
						this.path = response.ICETopicDependency.ICETopics.ICETopic.iceTopicPath;

						var html = response.ICETopicDependency.ICETopics.ICETopic.content ;
            			var div = document.createElement("div");
						div.innerHTML = html;
						response.ICETopicDependency.ICETopics.ICETopic.content = div.textContent || div.innerText || "";

						this.iceTopicName = response.ICETopicDependency.ICETopics.ICETopic.content;
						this.file = response.ICETopicDependency.ICETopics.ICETopic.FileName;					
						this.referenceCount = response.ICETopicDependency.ICETopics.ICETopic.referenceCount;
					}else {
						this.hasList = true;

						this.referencesNew = response.ICETopicDependency.ICETopics.ICETopic;
					}
				}
			}

			else if(this.dependencyType == "ICETopicContentDependency" ){
				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.ICETopicContentDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.ICETopicContentDependency.ICETopicContents.dependencyCount == 1){
						this.hasList = false;
						this.path = response.ICETopicContentDependency.ICETopicContents.ICETopicContent.iceTopicContentPath;

						var html = response.ICETopicContentDependency.ICETopicContents.ICETopicContent.content ;
            			var div = document.createElement("div");
						div.innerHTML = html;
						response.ICETopicContentDependency.ICETopicContents.ICETopicContent.content = div.textContent || div.innerText || "";

						var html2 = response.ICETopicContentDependency.ICETopicContents.ICETopicContent.TopicName ;
            			var div2 = document.createElement("div");
						div2.innerHTML = html2;
						response.ICETopicContentDependency.ICETopicContents.ICETopicContent.TopicName = div2.textContent || div2.innerText || "";

						this.iceTopicName = response.ICETopicContentDependency.ICETopicContents.ICETopicContent.TopicName;
						this.iceTopicContentName = response.ICETopicContentDependency.ICETopicContents.ICETopicContent.content;
						this.file = response.ICETopicContentDependency.ICETopicContents.ICETopicContent.FileName;					
						this.referenceCount = response.ICETopicContentDependency.ICETopicContents.ICETopicContent.referenceCount;
					}
					else {
						this.hasList = true;

						this.referencesNew = response.ICETopicContentDependency.ICETopicContents.ICETopicContent;
					}
				}
			}
			else if(this.dependencyType == "SystemDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.SystemDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.SystemDependency.Systems.dependencyCount == 1){
						this.hasList = false;
						this.file = response.SystemDependency.Systems.System.SystemName;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.SystemDependency.Systems.System;
					}

			}

			}
			else if(this.dependencyType == "DocumentDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.DocumentDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.DocumentDependency.Documents.dependencyCount == 1){
						this.hasList = false;
						this.file = response.DocumentDependency.Documents.Document.DocumentName;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.DocumentDependency.Documents.Document;
					}

			}

			}
			else if(this.dependencyType == "PunchlistDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.PunchlistDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.PunchlistDependency.Punchlists.dependencyCount == 1){
						this.hasList = false;
						this.file = response.PunchlistDependency.Punchlists.Punchlist.punchlistDescrip;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.PunchlistDependency.Punchlists.Punchlist;
					}

			}

			}
			else if(this.dependencyType == "MCCDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.MCCDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.MCCDependency.MCCs.dependencyCount == 1){
						this.hasList = false;
						this.file = response.MCCDependency.MCCs.MCC.MCCName;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.MCCDependency.MCCs.MCC;
					}

			}

			}
			else if(this.dependencyType == "SCCDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.SCCDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.SCCDependency.SCCs.dependencyCount == 1){
						this.hasList = false;
						this.file = response.SCCDependency.SCCs.SCC.SCCName;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.SCCDependency.SCCs.SCC;
					}

			}

			}
			else if(this.dependencyType == "CCCDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.CCCDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.CCCDependency.CCCs.dependencyCount == 1){
						this.hasList = false;
						this.file = response.CCCDependency.CCCs.CCC.CCCName;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.CCCDependency.CCCs.CCC;
					}

			}

			}
			else if(this.dependencyType == "STNDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.STNDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.STNDependency.STNs.dependencyCount == 1){
						this.hasList = false;
						this.file = response.STNDependency.STNs.STN.STNName;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.STNDependency.STNs.STN;
					}

			}

			}
			else if(this.dependencyType == "SHCDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.SHCDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.SHCDependency.SHCs.dependencyCount == 1){
						this.hasList = false;
						this.file = response.SHCDependency.SHCs.SHC.SHCName;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.SHCDependency.SHCs.SHC;
					}

			}

			}
			else if(this.dependencyType == "SCSDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.SCSDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.SCSDependency.SCSs.dependencyCount == 1){
						this.hasList = false;
						this.file = response.SCSDependency.SCSs.SCS.SCSName;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.SCSDependency.SCSs.SCS;
					}

			}

			}
			else if(this.dependencyType == "TopicMapOutputFacilityDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.TopicMapOutputFacilityDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.TopicMapOutputFacilityDependency.TopicMapOutputs.dependencyCount == 1){
						this.hasList = false;
						this.file = response.TopicMapOutputFacilityDependency.TopicMapOutputs.TopicMapOutput.docName;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.TopicMapOutputFacilityDependency.TopicMapOutputs.TopicMapOutput;
					}

			}

			}

			else if(this.dependencyType == "TopicMapOutputDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.TopicMapOutputDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.TopicMapOutputDependency.TopicMapOutputs.dependencyCount == 1){
						this.hasList = false;
						this.file = response.TopicMapOutputDependency.TopicMapOutputs.TopicMapOutput.docName;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.TopicMapOutputDependency.TopicMapOutputs.TopicMapOutput;
					}

			}

			}
			

			this.isShowReferences = true;
			this.setdataToGrid();
			this.gridOptions1.api.sizeColumnsToFit();
			this.gridOptions2.api.sizeColumnsToFit();
		} 
		

		else {
				this.isShowReferences = true;
				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;
				this.hasRefs = true;
				this.loading = false;

				this.rowDataRef = [];
				var deptype = "";
				var depname = "";
				var refcount = "";

				if (this.responseForRef.TopicDependency != undefined) {
				if (this.responseForRef.TopicDependency != '') {
					if (this.responseForRef.TopicDependency.Topics.dependencyCount != 1) {
						let refList = this.responseForRef.TopicDependency.Topics.Topic;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "Topic Dependency";						
							depname = refList[i].content;
							refcount = refList[i].referenceCount;

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "Topic Dependency";
						depname = this.responseForRef.TopicDependency.Topics.Topic.content;
						refcount = this.responseForRef.TopicDependency.Topics.Topic.referenceCount;
						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }					
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }}
				if (this.responseForRef.ProcedureDependency != undefined) {
				if (this.responseForRef.ProcedureDependency != '') {
					if (this.responseForRef.ProcedureDependency.Procedures.dependencyCount != 1) {
						let refList = this.responseForRef.ProcedureDependency.Procedures.Procedure;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "Procedure Dependency";						
							depname = refList[i].content;
							refcount = refList[i].referenceCount;

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "Procedure Dependency";
						depname = this.responseForRef.ProcedureDependency.Procedures.Procedure.content;
						refcount = this.responseForRef.ProcedureDependency.Procedures.Procedure.referenceCount;

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }}
				if (this.responseForRef.ICECourseDependency != undefined) {
				if (this.responseForRef.ICECourseDependency != '') {
					if (this.responseForRef.ICECourseDependency.ICECourses.dependencyCount != 1) {
						let refList = this.responseForRef.ICECourseDependency.ICECourses.ICECourse;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "ICE Course Dependency";	
							var html = refList[i].content ;
							var div = document.createElement("div");
							div.innerHTML = html;
							refList[i].content = div.textContent || div.innerText || "";
							//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;					
							depname = refList[i].FileName;
							refcount = refList[i].referenceCount;

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "ICE Course Dependency";
						var html = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content ;
            			var div = document.createElement("div");
						div.innerHTML = html;
						this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content = div.textContent || div.innerText || "";
						depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.FileName;
						//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;
						refcount = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.referenceCount;

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }}
				if (this.responseForRef.ICETopicDependency != undefined) {
				if (this.responseForRef.ICETopicDependency != '') {
					if (this.responseForRef.ICETopicDependency.ICETopics.dependencyCount != 1) {
						let refList = this.responseForRef.ICETopicDependency.ICETopics.ICETopic;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "ICE Topic Dependency";	
							var html = refList[i].content ;
							var div = document.createElement("div");
							div.innerHTML = html;
							refList[i].content = div.textContent || div.innerText || "";
							//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;					
							depname = refList[i].FileName;
							refcount = refList[i].referenceCount;

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "ICE Topic Dependency";
						var html = this.responseForRef.ICETopicDependency.ICETopics.ICETopic.content ;
            			var div = document.createElement("div");
						div.innerHTML = html;
						this.responseForRef.ICETopicDependency.ICETopics.ICETopic.content = div.textContent || div.innerText || "";
						depname = this.responseForRef.ICETopicDependency.ICETopics.ICETopic.FileName;
						//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;
						refcount = this.responseForRef.ICETopicDependency.ICETopics.ICETopic.referenceCount;

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }}
				if (this.responseForRef.ICETopicContentDependency != undefined) {
				if (this.responseForRef.ICETopicContentDependency != '') {
					if (this.responseForRef.ICETopicContentDependency.ICETopicContents.dependencyCount != 1) {
						let refList = this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "ICE Topic Content Dependency";	
							var html = refList[i].content ;
							var div = document.createElement("div");
							div.innerHTML = html;
							refList[i].content = div.textContent || div.innerText || "";
							//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;					
							depname = refList[i].FileName;
							refcount = refList[i].referenceCount;

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "ICE Topic Content Dependency";
						var html = this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent.content ;
            			var div = document.createElement("div");
						div.innerHTML = html;
						this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent.content = div.textContent || div.innerText || "";
						depname = this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent.FileName;
						//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;
						refcount = this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent.referenceCount;

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }}
				if (this.responseForRef.TagDependency != undefined) {
				if (this.responseForRef.TagDependency != '') {
					if (this.responseForRef.TagDependency.Tags.dependencyCount != 1) {
						let refList = this.responseForRef.TagDependency.Tags.Tag;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "Tag Dependency";						
							depname = refList[i].content;
							refcount = "1";

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "Tag Dependency";
						depname = this.responseForRef.TagDependency.Tags.Tag.content;
						refcount = "1";

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }}
				if (this.responseForRef.TagAttributeDependency != undefined) {
				if (this.responseForRef.TagAttributeDependency != '') {
					if (this.responseForRef.TagAttributeDependency.TagAttribute.dependencyCount != 1) {
						let refList = this.responseForRef.TagAttributeDependency.TagAttribute.TagAttribute;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "Tag Attribute Dependency";						
							depname = refList[i].tagNo;
							refcount = "1";

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "Tag Attribute Dependency";
						depname = this.responseForRef.TagAttributeDependency.TagAttribute.TagAttribute.tagNo;
						refcount = "1";

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }}
				if (this.responseForRef.SystemDependency != undefined) {
				if (this.responseForRef.SystemDependency != '') {
					if (this.responseForRef.SystemDependency.Systems.dependencyCount != 1) {
						let refList = this.responseForRef.SystemDependency.Systems.System;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "System Dependency";	
							var html = refList[i].systemName ;
							var div = document.createElement("div");
							div.innerHTML = html;
							refList[i].systemName = div.textContent || div.innerText || "";					
							depname = refList[i].SystemName;
							refcount = "1";

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "System Dependency";
						var html = this.responseForRef.SystemDependency.Systems.System.SystemName ;
						var div = document.createElement("div");
						div.innerHTML = html;
						this.responseForRef.SystemDependency.Systems.System.SystemName = div.textContent || div.innerText || "";	
						depname = this.responseForRef.SystemDependency.Systems.System.SystemName;
						refcount = "1";

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }}
				if (this.responseForRef.DocumentDependency != undefined) {
					if (this.responseForRef.DocumentDependency != '') {
						if (this.responseForRef.DocumentDependency.Documents.dependencyCount != 1) {
							let refList = this.responseForRef.DocumentDependency.Documents.Document;
							for(let i = 0; i < refList.length; i++){
								
								deptype = "Document Dependency";						
								depname = refList[i].DocumentName;
								refcount = "1";
	
								this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});
	
							}
							
						} else {
							deptype = "Document Dependency";
							depname = this.responseForRef.DocumentDependency.Documents.Document.DocumentName;
							refcount = "1";
	
							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});
	
						}
						var deptype = "";
						var depname = "";
						var refcount = "";
					}}
				if (this.responseForRef.ImageDependency != undefined) {
				if (this.responseForRef.ImageDependency != '') {
					if (this.responseForRef.ImageDependency.Images.dependencyCount != 1) {
						let refList = this.responseForRef.ImageDependency.Images.Image;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "Image Dependency";						
							depname = refList[i].ImageName;
							refcount = refList[i].refcount;

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "Image Dependency";
						depname = this.responseForRef.ImageDependency.Images.Image.ImageName;
						refcount = this.responseForRef.ImageDependency.Images.Image.refcount;

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }}
				if (this.responseForRef.TopicMapDependency != undefined) {
				if (this.responseForRef.TopicMapDependency != '') {
					if (this.responseForRef.TopicMapDependency.TopicMaps.dependencyCount != 1) {
						let refList = this.responseForRef.TopicMapDependency.TopicMaps.TopicMap;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "Topic Map Dependency";						
							depname = refList[i].topicMapName;
							refcount = refList[i].refcount;

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "Topic Map Dependency";
						depname = this.responseForRef.TopicMapDependency.TopicMaps.TopicMap.topicMapName;
						refcount = this.responseForRef.TopicMapDependency.TopicMaps.TopicMap.refcount;

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }}
				if (this.responseForRef.ContentDependency != undefined) {
				if (this.responseForRef.ContentDependency != '') {
					if (this.responseForRef.ContentDependency.ContentItems.dependencyCount != 1) {
						let refList = this.responseForRef.ContentDependency.ContentItems.ContentItem;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "File Dependency";						
							depname = refList[i].ContentName;
							refcount = refcount = refList[i].refcount;;

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "File Dependency";
						depname = this.responseForRef.ContentDependency.ContentItems.ContentItem.ContentName;
						refcount = this.responseForRef.ContentDependency.ContentItems.ContentItem.refcount;

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }}
				if (this.responseForRef.VideoDependency != undefined) {
				if (this.responseForRef.VideoDependency != '') {
					if (this.responseForRef.VideoDependency.Videos.dependencyCount != 1) {
						let refList = this.responseForRef.VideoDependency.Videos.Video;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "Video Dependency";						
							depname = refList[i].VideoName;
							refcount = refcount = refList[i].refcount;;

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "Video Dependency";
						depname = this.responseForRef.VideoDependency.Videos.Video.VideoName;
						refcount = this.responseForRef.VideoDependency.Videos.Video.refcount;

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }}
				if (this.responseForRef.ThreeDModelDependency != undefined) {
				if (this.responseForRef.ThreeDModelDependency != '') {
					if (this.responseForRef.ThreeDModelDependency.ThreeDModels.dependencyCount != 1) {
						let refList = this.responseForRef.ThreeDModelDependency.ThreeDModels.ThreeDModel;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "ThreeD Model Dependency";						
							depname = refList[i].ThreeDModelName;
							refcount = refcount = refList[i].refcount;;

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "ThreeD Model Dependency";
						depname = this.responseForRef.ThreeDModelDependency.ThreeDModels.ThreeDModel.ThreeDModelName;
						refcount = this.responseForRef.ThreeDModelDependency.ThreeDModels.ThreeDModel.refcount;

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }}
				if (this.responseForRef.CheckSheetDependency != undefined) {
				if (this.responseForRef.CheckSheetDependency != '') {
					if (this.responseForRef.CheckSheetDependency.CheckSheets.dependencyCount != 1) {
						let refList = this.responseForRef.CheckSheetDependency.CheckSheets.CheckSheet;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "Check Sheet Dependency";						
							depname = refList[i].checkSheetName;
							refcount = "1";

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "Check Sheet Dependency";
						depname = this.responseForRef.CheckSheetDependency.CheckSheets.CheckSheet.checkSheetName;
						refcount = "1";

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }}
				if (this.responseForRef.MCCDependency != undefined) {
				if (this.responseForRef.MCCDependency != '') {
					if (this.responseForRef.MCCDependency.MCCs.dependencyCount != 1) {
						let refList = this.responseForRef.MCCDependency.MCCs.MCC;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "Mechanical Completion Certificate Dependency";						
							depname = refList[i].MCCName;
							refcount = "1";

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "Mechanical Completion Certificate Dependency";
						depname = this.responseForRef.MCCDependency.MCCs.MCC.MCCName;
						refcount = "1";

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }}
				if (this.responseForRef.SCCDependency != undefined) {
				if (this.responseForRef.SCCDependency != '') {
					if (this.responseForRef.SCCDependency.SCCs.dependencyCount != 1) {
						let refList = this.responseForRef.SCCDependency.SCCs.SCC;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "Static Completion Certificate Dependency";						
							depname = refList[i].SCCName;
							refcount = "1";

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "Static Completion Certificate Dependency";
						depname = this.responseForRef.SCCDependency.SCCs.SCC.SCCName;
						refcount = "1";

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }}
				if (this.responseForRef.SHCDependency != undefined) {
				if (this.responseForRef.SHCDependency != '') {
					if (this.responseForRef.SHCDependency.SHCs.dependencyCount != 1) {
						let refList = this.responseForRef.SHCDependency.SHCs.SHC;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "System Handover Certificate Dependency";						
							depname = refList[i].SHCName;
							refcount = "1";

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "System Handover Certificate Dependency";
						depname = this.responseForRef.SHCDependency.SHCs.SHC.SHCName;
						refcount = "1";

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }}
				if (this.responseForRef.CCCDependency != undefined) {
				if (this.responseForRef.CCCDependency != '') {
					if (this.responseForRef.CCCDependency.CCCs.dependencyCount != 1) {
						let refList = this.responseForRef.CCCDependency.CCCs.CCC;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "Comm Completion Certificate Dependency";						
							depname = refList[i].CCCName;
							refcount = "1";

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "Comm Completion Certificate Dependency";
						depname = this.responseForRef.CCCDependency.CCCs.CCC.CCCName;
						refcount = "1";

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }}
				if (this.responseForRef.STNDependency != undefined) {
				if (this.responseForRef.STNDependency != '') {
					if (this.responseForRef.STNDependency.STNs.dependencyCount != 1) {
						let refList = this.responseForRef.STNDependency.STNs.STN;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "System Turnover Notice Dependency";						
							depname = refList[i].STNName;
							refcount = "1";

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "System Turnover Notice Dependency";
						depname = this.responseForRef.STNDependency.STNs.STN.STNName;
						refcount = "1";

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }}
				if (this.responseForRef.SCSDependency != undefined) {
					if (this.responseForRef.SCSDependency != '') {
						if (this.responseForRef.SCSDependency.SCSs.dependencyCount != 1) {
							let refList = this.responseForRef.SCSDependency.SCSs.SCS;
							for(let i = 0; i < refList.length; i++){
								
								deptype = "System Completion Status Dependency";						
								depname = refList[i].SCSName;
								refcount = "1";
	
								this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});
	
							}
							
						} else {
							deptype = "System Completion Status Dependency";
							depname = this.responseForRef.SCSDependency.SCSs.SCS.SCSName;
							refcount = "1";
	
							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});
	
						}
						var deptype = "";
						var depname = "";
						var refcount = "";
					}}

					if (this.responseForRef.TopicMapOutputFacilityDependency != undefined) {
						if (this.responseForRef.TopicMapOutputFacilityDependency != '') {
							if (this.responseForRef.TopicMapOutputFacilityDependency.TopicMapOutputs.dependencyCount != 1) {
								let refList = this.responseForRef.TopicMapOutputFacilityDependency.TopicMapOutputs.TopicMapOutput;
								for(let i = 0; i < refList.length; i++){
									
									deptype = "Topic Map Output Dependency";						
									depname = refList[i].docName;
									refcount = "1";
		
									this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});
		
								}
								
							} else {
								deptype = "Topic Map Output Dependency";
								depname = this.responseForRef.TopicMapOutputFacilityDependency.TopicMapOutputs.TopicMapOutput.docName;
								refcount = "1";
		
								this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});
		
							}
							var deptype = "";
							var depname = "";
							var refcount = "";
						}}

					if (this.responseForRef.TopicMapOutputDependency != undefined) {
						if (this.responseForRef.TopicMapOutputDependency != '') {
							if (this.responseForRef.TopicMapOutputDependency.TopicMapOutputs.dependencyCount != 1) {
								let refList = this.responseForRef.TopicMapOutputDependency.TopicMapOutputs.TopicMapOutput;
								for(let i = 0; i < refList.length; i++){
									
									deptype = "Topic Map Output Dependency";						
									depname = refList[i].docName;
									refcount = "1";
		
									this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});
		
								}
								
							} else {
								deptype = "Topic Map Output Dependency";
								depname = this.responseForRef.TopicMapOutputDependency.TopicMapOutputs.TopicMapOutput.docName;
								refcount = "1";
		
								this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});
		
							}
							var deptype = "";
							var depname = "";
							var refcount = "";
						}}
				if (this.responseForRef.PunchlistDependency != undefined) {
				if (this.responseForRef.PunchlistDependency != '') {
					if (this.responseForRef.PunchlistDependency.Punchlists.dependencyCount != 1) {
						let refList = this.responseForRef.PunchlistDependency.Punchlists.Punchlist;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "Punchlist Dependency";						
							depname = refList[i].punchlistDescrip;
							refcount = "1";

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "Punchlist Dependency";
						depname = this.responseForRef.PunchlistDependency.Punchlists.Punchlist.punchlistDescrip;
						refcount = "1";

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }}
				this.gridOptions1.api.sizeColumnsToFit();

		}
	}
		}

		else if (serviceType == WsType.GET_TAG_DEPENDENCIES_BY_TAG) {
			this.loadingService.hideLoading();
			let response = JSON.parse(data.payload);
			this.responseForRef = response;

			if (this.viewWidgetId == 'tags_total_tag_reference' && this.used){

				this.isShowReferences = true;
				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;
				this.hasRefs = true;
				this.loading = false;

				this.rowDataRef = [];
				var deptype = "";
				var depname = "";
				var refcount = "";

				if (this.responseForRef.TopicDependency != undefined) {
					if (this.responseForRef.TopicDependency != '') {
						if (this.responseForRef.TopicDependency.Topics.dependencyCount != 1) {
							let refList = this.responseForRef.TopicDependency.Topics.Topic;
							for(let i = 0; i < refList.length; i++){
								
								deptype = "Topic Dependency";						
								depname = refList[i].content;
								refcount = refList[i].referenceCount;
								path = refList[i].topicPath;
	
								this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount, path:path});
	
							}
							
						} else {
							deptype = "Topic Dependency";
							depname = this.responseForRef.TopicDependency.Topics.Topic.content;
							refcount = this.responseForRef.TopicDependency.Topics.Topic.referenceCount;
							path = this.responseForRef.TopicDependency.Topics.Topic.topicPath;
							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount, path: path});
	
						}					
						var deptype = "";
						var depname = "";
						var refcount = "";
						var path = "";
					}}
					if (this.responseForRef.ProcedureDependency != undefined) {
					if (this.responseForRef.ProcedureDependency != '') {
						if (this.responseForRef.ProcedureDependency.Procedures.dependencyCount != 1) {
							let refList = this.responseForRef.ProcedureDependency.Procedures.Procedure;
							for(let i = 0; i < refList.length; i++){
								
								deptype = "Procedure Dependency";						
								depname = refList[i].content;
								refcount = refList[i].referenceCount;
								path = refList[i].procPath;
								this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount, path: path});
	
							}
							
						} else {
							deptype = "Procedure Dependency";
							depname = this.responseForRef.ProcedureDependency.Procedures.Procedure.content;
							refcount = this.responseForRef.ProcedureDependency.Procedures.Procedure.referenceCount;
							path = this.responseForRef.ProcedureDependency.Procedures.Procedure.procPath;
							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount, path: path});
	
						}
						var deptype = "";
						var depname = "";
						var refcount = "";
						var path = "";
					}}
				if (this.responseForRef.ICECourseDependency != undefined) {
					if (this.responseForRef.ICECourseDependency != '') {
						if (this.responseForRef.ICECourseDependency.ICECourses.dependencyCount != 1) {
							let refList = this.responseForRef.ICECourseDependency.ICECourses.ICECourse;
							for(let i = 0; i < refList.length; i++){
								
								deptype = "ICE Course Dependency";	
								var html = refList[i].content ;
								var div = document.createElement("div");
								div.innerHTML = html;
								refList[i].content = div.textContent || div.innerText || "";
								//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;					
								depname = refList[i].FileName;
								refcount = refList[i].referenceCount;
								path = refList[i].iceCoursePath;
	
								this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount, path: path});
	
							}
							
						} else {
							deptype = "ICE Course Dependency";
							var html = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content ;
							var div = document.createElement("div");
							div.innerHTML = html;
							this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content = div.textContent || div.innerText || "";
							depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.FileName;
							//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;
							refcount = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.referenceCount;
							path = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.iceCoursePath;
	
							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount, path: path});
	
						}
						var deptype = "";
						var depname = "";
						var refcount = "";
						var path = "";
					}}
					if (this.responseForRef.ICETopicDependency != undefined) {
					if (this.responseForRef.ICETopicDependency != '') {
						if (this.responseForRef.ICETopicDependency.ICETopics.dependencyCount != 1) {
							let refList = this.responseForRef.ICETopicDependency.ICETopics.ICETopic;
							for(let i = 0; i < refList.length; i++){
								
								deptype = "ICE Topic Dependency";	
								var html = refList[i].content ;
								var div = document.createElement("div");
								div.innerHTML = html;
								refList[i].content = div.textContent || div.innerText || "";
								//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;					
								depname = refList[i].FileName;
								refcount = refList[i].referenceCount;
								path = refList[i].iceTopicPath;
	
								this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount, path: path});
	
							}
							
						} else {
							deptype = "ICE Topic Dependency";
							var html = this.responseForRef.ICETopicDependency.ICETopics.ICETopic.content ;
							var div = document.createElement("div");
							div.innerHTML = html;
							this.responseForRef.ICETopicDependency.ICETopics.ICETopic.content = div.textContent || div.innerText || "";
							depname = this.responseForRef.ICETopicDependency.ICETopics.ICETopic.FileName;
							//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;
							refcount = this.responseForRef.ICETopicDependency.ICETopics.ICETopic.referenceCount;
							path = this.responseForRef.ICETopicDependency.ICETopics.ICETopic.iceTopicPath;
							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount, path: path});
	
						}
						var deptype = "";
						var depname = "";
						var refcount = "";
						var path = "";
					}}
					if (this.responseForRef.ICETopicContentDependency != undefined) {
					if (this.responseForRef.ICETopicContentDependency != '') {
						if (this.responseForRef.ICETopicContentDependency.ICETopicContents.dependencyCount != 1) {
							let refList = this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent;
							for(let i = 0; i < refList.length; i++){
								
								deptype = "ICE Topic Content Dependency";	
								var html = refList[i].content ;
								var div = document.createElement("div");
								div.innerHTML = html;
								refList[i].content = div.textContent || div.innerText || "";
								//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;					
								depname = refList[i].FileName;
								refcount = refList[i].referenceCount;
								path = refList[i].iceTopicContentPath;
	
								this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount, path : path});
	
							}
							
						} else {
							deptype = "ICE Topic Content Dependency";
							var html = this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent.content ;
							var div = document.createElement("div");
							div.innerHTML = html;
							this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent.content = div.textContent || div.innerText || "";
							depname = this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent.FileName;
							//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;
							refcount = this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent.referenceCount;
							path =  this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent.iceTopicContentPath;
							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount, path: path});
	
						}
						var deptype = "";
						var depname = "";
						var refcount = "";
						var path = "";
					}}
					this.gridOptions1.api.sizeColumnsToFit();

			}

			else {
			if(!this.deprefcountclicked){
			response = this.filterDependencies(response);

			if(this.dependencyType == "TopicDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.TopicDependency == ''){
					this.hasRefs = false;
               		this.loading = false;

				}else {
					this.hasRefs = true;
					this.loading = false;

					if(response.TopicDependency.Topics.dependencyCount == 1){
						this.hasList = false;
						this.path = response.TopicDependency.Topics.Topic.topicPath;
						this.file = response.TopicDependency.Topics.Topic.content;
						this.referenceCount = response.TopicDependency.Topics.Topic.referenceCount;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.TopicDependency.Topics.Topic;
					}
				}
									
			} else if(this.dependencyType == "ProcedureDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.ProcedureDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.ProcedureDependency.Procedures.dependencyCount == 1){
						this.hasList = false;
						this.path = response.ProcedureDependency.Procedures.Procedure.procPath;
						this.file = response.ProcedureDependency.Procedures.Procedure.content;
						this.referenceCount = response.ProcedureDependency.Procedures.Procedure.referenceCount;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.ProcedureDependency.Procedures.Procedure;
					}
			}
			} else if(this.dependencyType == "TagDependency" ){
				
				if(response.TagDependency == ''){
					this.hasRefs = false;
               		this.loading = false;

				}else {
					this.hasRefs = true;
					this.loading = false;

					if(response.TagDependency.Tags.dependencyCount == 1){
						this.hasList = false;
						this.file = response.TagDependency.Tags.Tag.tagNo;
					}	else {
						this.hasList = true;

						this.referencesNew = response.TagDependency.Tags.Tag;
					}
				}

			} else if(this.dependencyType == "ICECourseDependency" ){

				if(response.ICECourseDependency == ''){
					this.hasRefs = false;
               		this.loading = false;

				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.ICECourseDependency.ICECourses.dependencyCount == 1){
						this.hasList = false;
						this.path = response.ICECourseDependency.ICECourses.ICECourse.iceCoursePath;

						var html = response.ICECourseDependency.ICECourses.ICECourse.content ;
            			var div = document.createElement("div");
						div.innerHTML = html;
						response.ICECourseDependency.ICECourses.ICECourse.content = div.textContent || div.innerText || "";

						this.file = response.ICECourseDependency.ICECourses.ICECourse.FileName;					
						this.referenceCount = response.ICECourseDependency.ICECourses.ICECourse.referenceCount;
					}
					else {
						this.hasList = true;

						this.referencesNew = response.ICECourseDependency.ICECourses.ICECourse;
					}
				}
			}
			else if(this.dependencyType == "ICETopicDependency" ){

				if(response.ICETopicDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.ICETopicDependency.ICETopics.dependencyCount == 1){
						this.hasList = false;
						this.path = response.ICETopicDependency.ICETopics.ICETopic.iceTopicPath;

						var html = response.ICETopicDependency.ICETopics.ICETopic.content ;
            			var div = document.createElement("div");
						div.innerHTML = html;
						response.ICETopicDependency.ICETopics.ICETopic.content = div.textContent || div.innerText || "";

						this.iceTopicName = response.ICETopicDependency.ICETopics.ICETopic.content;
						this.file = response.ICETopicDependency.ICETopics.ICETopic.FileName;					
						this.referenceCount = response.ICETopicDependency.ICETopics.ICETopic.referenceCount;
					}else {
						this.hasList = true;

						this.referencesNew = response.ICETopicDependency.ICETopics.ICETopic;
					}
				}
			}

			else if(this.dependencyType == "ICETopicContentDependency" ){

				if(response.ICETopicContentDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.ICETopicContentDependency.ICETopicContents.dependencyCount == 1){
						this.hasList = false;
						this.path = response.ICETopicContentDependency.ICETopicContents.ICETopicContent.iceTopicContentPath;

						var html = response.ICETopicContentDependency.ICETopicContents.ICETopicContent.content ;
            			var div = document.createElement("div");
						div.innerHTML = html;
						response.ICETopicContentDependency.ICETopicContents.ICETopicContent.content = div.textContent || div.innerText || "";

						var html2 = response.ICETopicContentDependency.ICETopicContents.ICETopicContent.TopicName ;
            			var div2 = document.createElement("div");
						div2.innerHTML = html2;
						response.ICETopicContentDependency.ICETopicContents.ICETopicContent.TopicName = div2.textContent || div2.innerText || "";

						this.iceTopicName = response.ICETopicContentDependency.ICETopicContents.ICETopicContent.TopicName;
						this.iceTopicContentName = response.ICETopicContentDependency.ICETopicContents.ICETopicContent.content;
						this.file = response.ICETopicContentDependency.ICETopicContents.ICETopicContent.FileName;					
						this.referenceCount = response.ICETopicContentDependency.ICETopicContents.ICETopicContent.referenceCount;
					}
					else {
						this.hasList = true;

						this.referencesNew = response.ICETopicContentDependency.ICETopicContents.ICETopicContent;
					}
				}
			}
			else if(this.dependencyType == "AssetParentDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.AssetParentDependency == ''){
					this.hasRefs = false;
               		this.loading = false;

				}else {
					this.hasRefs = true;
					this.loading = false;

					if(response.AssetParentDependency.AssetParents.dependencyCount == 1){
						this.hasList = false;
						this.file = response.AssetParentDependency.AssetParents.AssetParent.dependencyName;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.AssetParentDependency.AssetParents.AssetParent;
					}
				}
									
			}
			else if(this.dependencyType == "AssetAttrDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.AssetAttrDependency == ''){
					this.hasRefs = false;
               		this.loading = false;

				}else {
					this.hasRefs = true;
					this.loading = false;

					if(response.AssetAttrDependency.AssetAttributes.dependencyCount == 1){
						this.hasList = false;
						this.file = response.AssetAttrDependency.AssetAttributes.AssetAttr.dependencyName;				
					}
					else {
						this.hasList = true;
						this.referencesNew = response.AssetAttrDependency.AssetAttributes.AssetAttr;
					}
				}
									
			}
			else if(this.dependencyType == "CheckSheetDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.CheckSheetDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.CheckSheetDependency.CheckSheets.dependencyCount == 1){
						this.hasList = false;
						this.file = response.CheckSheetDependency.CheckSheets.CheckSheet.checkSheetName;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.CheckSheetDependency.CheckSheets.CheckSheet;
					}
			}
			} else if(this.dependencyType == "CommWorkItemDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.CommWorkItemDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.CommWorkItemDependency.WorkItems.dependencyCount == 1){
						this.hasList = false;
						this.file = response.CommWorkItemDependency.WorkItems.WorkItem.dependencyName;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.CommWorkItemDependency.WorkItems.WorkItem;
					}
			}
			} else if(this.dependencyType == "PunchlistDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.PunchlistDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.PunchlistDependency.Punchlists.dependencyCount == 1){
						this.hasList = false;
						this.file = response.PunchlistDependency.Punchlists.Punchlist.punchlistDescrip;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.PunchlistDependency.Punchlists.Punchlist;
					}
			}
			} else if(this.dependencyType == "IRNDependency"){

				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;

				if(response.IRNDependency == ''){
					this.hasRefs = false;
               		this.loading = false;
				}else {

					this.hasRefs = true;
					this.loading = false;

					if(response.IRNDependency.IRNs.dependencyCount == 1){
						this.hasList = false;
						this.file = response.IRNDependency.IRNs.IRN.dependencyName;
					}
					else {
						this.hasList = true;
						this.referencesNew = response.IRNDependency.IRNs.IRN;
					}
			}
			}
		
		
			this.isShowReferences = true;
			this.setdataToGrid();
			this.gridOptions1.api.sizeColumnsToFit();

		}
		else{

				this.isShowReferences = true;
				this.hasTagAttreRefs = false;
				this.loadingTagAttr = false;
				this.hasRefs = true;
				this.loading = false;

				this.rowDataRef = [];
				var deptype = "";
				var depname = "";
				var refcount = "";

				if (this.responseForRef.TopicDependency != '') {
					if (this.responseForRef.TopicDependency.Topics.dependencyCount != 1) {
						let refList = this.responseForRef.TopicDependency.Topics.Topic;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "Topic Dependency";						
							depname = refList[i].content;
							refcount = refList[i].referenceCount;

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "Topic Dependency";
						depname = this.responseForRef.TopicDependency.Topics.Topic.content;
						refcount = this.responseForRef.TopicDependency.Topics.Topic.referenceCount;
						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }					
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }
				if (this.responseForRef.ProcedureDependency != '') {
					if (this.responseForRef.ProcedureDependency.Procedures.dependencyCount != 1) {
						let refList = this.responseForRef.ProcedureDependency.Procedures.Procedure;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "Procedure Dependency";						
							depname = refList[i].content;
							refcount = refList[i].referenceCount;

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "Procedure Dependency";
						depname = this.responseForRef.ProcedureDependency.Procedures.Procedure.content;
						refcount = this.responseForRef.ProcedureDependency.Procedures.Procedure.referenceCount;

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }
				if (this.responseForRef.ICECourseDependency != '') {
					if (this.responseForRef.ICECourseDependency.ICECourses.dependencyCount != 1) {
						let refList = this.responseForRef.ICECourseDependency.ICECourses.ICECourse;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "ICE Course Dependency";	
							var html = refList[i].content ;
							var div = document.createElement("div");
							div.innerHTML = html;
							refList[i].content = div.textContent || div.innerText || "";
							//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;					
							depname = refList[i].FileName;
							refcount = refList[i].referenceCount;

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "ICE Course Dependency";
						var html = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content ;
            			var div = document.createElement("div");
						div.innerHTML = html;
						this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content = div.textContent || div.innerText || "";
						depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.FileName;
						//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;
						refcount = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.referenceCount;

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }
				if (this.responseForRef.ICETopicDependency != '') {
					if (this.responseForRef.ICETopicDependency.ICETopics.dependencyCount != 1) {
						let refList = this.responseForRef.ICETopicDependency.ICETopics.ICETopic;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "ICE Topic Dependency";	
							var html = refList[i].content ;
							var div = document.createElement("div");
							div.innerHTML = html;
							refList[i].content = div.textContent || div.innerText || "";
							//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;					
							depname = refList[i].FileName;
							refcount = refList[i].referenceCount;

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "ICE Topic Dependency";
						var html = this.responseForRef.ICETopicDependency.ICETopics.ICETopic.content ;
            			var div = document.createElement("div");
						div.innerHTML = html;
						this.responseForRef.ICETopicDependency.ICETopics.ICETopic.content = div.textContent || div.innerText || "";
						depname = this.responseForRef.ICETopicDependency.ICETopics.ICETopic.FileName;
						//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;
						refcount = this.responseForRef.ICETopicDependency.ICETopics.ICETopic.referenceCount;

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }
				if (this.responseForRef.ICETopicContentDependency != '') {
					if (this.responseForRef.ICETopicContentDependency.ICETopicContents.dependencyCount != 1) {
						let refList = this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "ICE Topic Content Dependency";	
							var html = refList[i].content ;
							var div = document.createElement("div");
							div.innerHTML = html;
							refList[i].content = div.textContent || div.innerText || "";
							//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;					
							depname = refList[i].FileName;
							refcount = refList[i].referenceCount;

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "ICE Topic Content Dependency";
						var html = this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent.content ;
            			var div = document.createElement("div");
						div.innerHTML = html;
						this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent.content = div.textContent || div.innerText || "";
						depname = this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent.FileName;
						//depname = this.responseForRef.ICECourseDependency.ICECourses.ICECourse.content;
						refcount = this.responseForRef.ICETopicContentDependency.ICETopicContents.ICETopicContent.referenceCount;

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }
				if (this.responseForRef.TagDependency != '') {
					if (this.responseForRef.TagDependency.Tags.dependencyCount != 1) {
						let refList = this.responseForRef.TagDependency.Tags.Tag;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "Tag Dependency";						
							depname = refList[i].content;
							refcount = "1";

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "Tag Dependency";
						depname = this.responseForRef.TagDependency.Tags.Tag.content;
						refcount = "1";

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }
				if (this.responseForRef.PunchlistDependency != '') {
					if (this.responseForRef.PunchlistDependency.Punchlists.dependencyCount != 1) {
						let refList = this.responseForRef.PunchlistDependency.Punchlists.Punchlist;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "Punchlist Dependency";						
							depname = refList[i].punchlistDescrip;
							refcount = "1";

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "Punchlist Dependency";
						depname = this.responseForRef.PunchlistDependency.Punchlists.Punchlist.punchlistDescrip;
						refcount = "1";

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }
				if (this.responseForRef.CheckSheetDependency != '') {
					if (this.responseForRef.CheckSheetDependency.CheckSheets.dependencyCount != 1) {
						let refList = this.responseForRef.CheckSheetDependency.CheckSheets.CheckSheet;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "Check Sheet Dependency";						
							depname = refList[i].checkSheetName;
							refcount = "1";

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "Check Sheet Dependency";
						depname = this.responseForRef.CheckSheetDependency.CheckSheets.CheckSheet.checkSheetName;
						refcount = "1";

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }
				if (this.responseForRef.IRNDependency != '') {
					if (this.responseForRef.IRNDependency.IRNs.dependencyCount != 1) {
						let refList = this.responseForRef.IRNDependency.IRNs.IRN;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "IRN Dependency";						
							depname = refList[i].dependencyName;
							refcount = "1";

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "IRN Dependency";
						depname = this.responseForRef.IRNDependency.IRNs.IRN.dependencyName;
						refcount = "1";

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }
				if (this.responseForRef.CommWorkItemDependency != '') {
					if (this.responseForRef.CommWorkItemDependency.WorkItems.dependencyCount != 1) {
						let refList = this.responseForRef.CommWorkItemDependency.WorkItems.WorkItem;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "Work Item Dependency";						
							depname = refList[i].dependencyName;
							refcount = "1";

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "Work Item Dependency";
						depname = this.responseForRef.CommWorkItemDependency.WorkItems.WorkItem.dependencyName;
						refcount = "1";

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }
				if (this.responseForRef.AssetParentDependency != '') {
					if (this.responseForRef.AssetParentDependency.AssetParents.dependencyCount != 1) {
						let refList = this.responseForRef.AssetParentDependency.AssetParents.AssetParent;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "Asset Parent Dependency";						
							depname = refList[i].dependencyName;
							refcount = "1";

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "Asset Parent Dependency";
						depname = this.responseForRef.AssetParentDependency.AssetParents.AssetParent.dependencyName;
						refcount = "1";

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }
				if (this.responseForRef.AssetAttrDependency != '') {
					if (this.responseForRef.AssetAttrDependency.AssetAttributes.dependencyCount != 1) {
						let refList = this.responseForRef.AssetAttrDependency.AssetAttributes.AssetAttr;
						for(let i = 0; i < refList.length; i++){
							
							deptype = "Asset Attribute Dependency";						
							depname = refList[i].dependencyName;
							refcount = "1";

							this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

						}
                        
                    } else {
						deptype = "Asset Attribute Dependency";
						depname = this.responseForRef.AssetAttrDependency.AssetAttributes.AssetAttr.dependencyName;
						refcount = "1";

						this.rowDataRef.push({id:this.refId, depType:deptype, depName:depname, refCount:refcount});

                    }
                    var deptype = "";
					var depname = "";
					var refcount = "";
                }
				this.gridOptions1.api.sizeColumnsToFit();

		}
		}
		
			

		}
		
	}

	onFail(data: WsResponse, serviceType: WsType): void {
        if (data != null && serviceType == WsType.ITEM_DEPENDENCY_LIST) {
            this.alertService.clear();
            this.alertService.error(data.statusDescription, true);
        }
		else if (serviceType == WsType.GET_TAG_DEPENDENCIES_BY_TAG) {
			this.alertService.error(data.statusDescription);
		}
	}



	setdataToGrid(){
		this.rowDataRef = [];
		this.rowDataTagAttr = [];
		var path = "";
		var file = "";
		var refcount = "";
		var tag = "";
		var tagAttr = "";
		var iceTopicName = "";
		var iceTopicContentName = "";

		if(this.dependencyType == "TopicDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					path: this.path,
					file: this.file,
					refcount: this.referenceCount
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].content;
				path = this.referencesNew[i].topicPath;
				refcount = this.referencesNew[i].referenceCount;

				this.rowDataRef.push({
					id: this.refId,
					path: path,
					file: file,
					refcount: refcount
		
				});
			}}

		}	else if(this.dependencyType == "ProcedureDependency"){

			if(!this.hasList){
				
				this.rowDataRef.push({
					id: this.refId,
					path: this.path,
					file: this.file,
					refcount: this.referenceCount
		
				});
				
			} else{
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].content;
				path = this.referencesNew[i].procPath;
				refcount = this.referencesNew[i].referenceCount;

				this.rowDataRef.push({
					id: this.refId,
					path: path,
					file: file,
					refcount: refcount
		
				});
			}}

		} else if(this.dependencyType == "TagDependency" && this.viewWidgetId != 'tags_total_tag_reference_ref_view'){

			if(!this.hasList){
				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file		
				});				
			} else{
				for(let i = 0; i < this.referencesNew.length; i++){
					file = this.referencesNew[i].tagNo;
					
					this.rowDataRef.push({
						id: this.refId,						
						file: file			
					});
				}
			}
			if(!this.hasTagAttreList){
			this.rowDataTagAttr.push({
				id: this.refId,
				tag: this.tagAttrTag,
				tagAttr: this.tagAttr
			});
			} else {

				for(let i = 0; i < this.responseForRef.TagAttributeDependency.TagAttribute.TagAttribute.length; i++){
					tag = this.responseForRef.TagAttributeDependency.TagAttribute.TagAttribute[i].tagNo;
					tagAttr = this.responseForRef.TagAttributeDependency.TagAttribute.TagAttribute[i].dependencyName;
	
					this.rowDataTagAttr.push({
						id: this.refId,						
						tag: tag,
						tagAttr: tagAttr
			
					});	
				}			
			}
		} else if(this.dependencyType == "ICECourseDependency"){

			if(!this.hasList){
				
				this.rowDataRef.push({
					id: this.refId,
					path: this.path,
					file: this.file,
					refcount: this.referenceCount
		
				});				
			} else{
			for(let i = 0; i < this.referencesNew.length; i++){

				var html = this.referencesNew[i].content ;
				var div = document.createElement("div");
				div.innerHTML = html;
				this.referencesNew[i].content = div.textContent || div.innerText || "";

				file = this.referencesNew[i].FileName;
				path = this.referencesNew[i].iceCoursePath;
				refcount = this.referencesNew[i].referenceCount;

				this.rowDataRef.push({
					id: this.refId,
					path: path,
					file: file,
					refcount: refcount		
				});

			}}
		} else if(this.dependencyType == "ICETopicDependency"){

			if(!this.hasList){
				
				this.rowDataRef.push({
					id: this.refId,
					path: this.path,
					file: this.file,
					refcount: this.referenceCount,
					iceTopicName: this.iceTopicName		
				});

			} else{
			for(let i = 0; i < this.referencesNew.length; i++){

				var html = this.referencesNew[i].content ;
				var div = document.createElement("div");
				div.innerHTML = html;
				this.referencesNew[i].content = div.textContent || div.innerText || "";

				file = this.referencesNew[i].FileName;
				path = this.referencesNew[i].iceTopicPath;
				refcount = this.referencesNew[i].referenceCount;
				iceTopicName = this.referencesNew[i].content;

				this.rowDataRef.push({
					id: this.refId,
					path: path,
					file: file,
					refcount: refcount,
					iceTopicName: iceTopicName	
				});
			}}
		} else if(this.dependencyType == "ICETopicContentDependency"){

			if(!this.hasList){
				
				this.rowDataRef.push({
					id: this.refId,
					path: this.path,
					file: this.file,
					refcount: this.referenceCount,	
					iceTopicName: this.iceTopicName,
					iceTopicContentName: this.iceTopicContentName
				});
				
			}
			else{
			for(let i = 0; i < this.referencesNew.length; i++){

				var html = this.referencesNew[i].content ;
				var div = document.createElement("div");
				div.innerHTML = html;
				this.referencesNew[i].content = div.textContent || div.innerText || "";

				var html2 = this.referencesNew[i].TopicName ;
				var div2 = document.createElement("div");
				div2.innerHTML = html2;
				this.referencesNew[i].TopicName = div2.textContent || div2.innerText || "";

				file = this.referencesNew[i].FileName;
				path = this.referencesNew[i].iceTopicContentPath;
				refcount = this.referencesNew[i].referenceCount;
				iceTopicContentName = this.referencesNew[i].content;
				iceTopicName = this.referencesNew[i].TopicName;

				this.rowDataRef.push({
					id: this.refId,
					path: path,
					file: file,
					refcount: refcount,
					iceTopicName: iceTopicName,
					iceTopicContentName: iceTopicContentName	
				});

			}}
		}
		else if(this.dependencyType == "AssetParentDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].dependencyName;

				this.rowDataRef.push({
					id: this.refId,					
					file: file					
		
				});
			}}

		}
		else if(this.dependencyType == "AssetAttrDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].dependencyName;

				this.rowDataRef.push({
					id: this.refId,					
					file: file					
		
				});
			}}

		} else if(this.dependencyType == "CheckSheetDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].checkSheetName;

				this.rowDataRef.push({
					id: this.refId,					
					file: file					
		
				});
			}}

		} else if(this.dependencyType == "CommWorkItemDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].dependencyName;

				this.rowDataRef.push({
					id: this.refId,					
					file: file					
		
				});
			}}

		} else if(this.dependencyType == "PunchlistDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].punchlistDescrip;

				this.rowDataRef.push({
					id: this.refId,					
					file: file					
		
				});
			}}

		} else if(this.dependencyType == "IRNDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].dependencyName;

				this.rowDataRef.push({
					id: this.refId,					
					file: file					
		
				});
			}}

		} else if(this.dependencyType == "TagDependency" && this.viewWidgetId == 'tags_total_tag_reference_ref_view'){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].tagNo;

				this.rowDataRef.push({
					id: this.refId,					
					file: file					
		
				});
			}}

		}
		else if(this.dependencyType == "ImageDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
					path: this.path,
					refcount: this.referenceCount,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].ImageName;
				path = this.referencesNew[i].FilePath;
				refcount = this.referencesNew[i].refcount;

				this.rowDataRef.push({
					id: this.refId,					
					file: file,
					path: path,
					refcount: refcount,				
		
				});
			}}

		}
		else if(this.dependencyType == "TopicMapDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
					path: this.path,
					refcount: this.referenceCount,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].topicMapName;
				path = this.referencesNew[i].FilePath;
				refcount = this.referencesNew[i].refcount;

				this.rowDataRef.push({
					id: this.refId,					
					file: file,
					path: path,
					refcount: refcount,					
		
				});
			}}

		}
		else if(this.dependencyType == "ContentDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
					path: this.path,
					refcount: this.referenceCount,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].ContentName;
				path = this.referencesNew[i].FilePath;
				refcount = this.referencesNew[i].refcount;

				this.rowDataRef.push({
					id: this.refId,					
					file: file,
					path: path,
					refcount: refcount,					
		
				});
			}}

		}
		else if(this.dependencyType == "VideoDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
					path: this.path,
					refcount: this.referenceCount,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].VideoName;
				path = this.referencesNew[i].FilePath;
				refcount = this.referencesNew[i].refcount;

				this.rowDataRef.push({
					id: this.refId,					
					file: file,
					path: path,
					refcount: refcount,					
		
				});
			}}

		}
		else if(this.dependencyType == "ThreeDModelDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
					path: this.path,
					refcount: this.referenceCount,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].ThreeDModelName;
				path = this.referencesNew[i].FilePath;
				refcount = this.referencesNew[i].refcount;

				this.rowDataRef.push({
					id: this.refId,					
					file: file,
					path: path,
					refcount: refcount,					
		
				});
			}}

		}
		else if(this.dependencyType == "SystemDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].SystemName;

				this.rowDataRef.push({
					id: this.refId,					
					file: file,				
		
				});
			}}

		}
		else if(this.dependencyType == "DocumentDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].DocumentName;

				this.rowDataRef.push({
					id: this.refId,					
					file: file,				
		
				});
			}}

		}
		else if(this.dependencyType == "PunchlistDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].punchlistDescrip;

				this.rowDataRef.push({
					id: this.refId,					
					file: file,				
		
				});
			}}

		}
		else if(this.dependencyType == "MCCDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].MCCName;

				this.rowDataRef.push({
					id: this.refId,					
					file: file,				
		
				});
			}}

		}
		else if(this.dependencyType == "SCCDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].SCCName;

				this.rowDataRef.push({
					id: this.refId,					
					file: file,				
		
				});
			}}

		}
		else if(this.dependencyType == "CCCDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].CCCName;

				this.rowDataRef.push({
					id: this.refId,					
					file: file,				
		
				});
			}}

		}
		else if(this.dependencyType == "STNDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].STNName;

				this.rowDataRef.push({
					id: this.refId,					
					file: file,				
		
				});
			}}

		}
		else if(this.dependencyType == "SHCDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].SHCName;

				this.rowDataRef.push({
					id: this.refId,					
					file: file,				
		
				});
			}}

		}
		else if(this.dependencyType == "SCSDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].SCSName;

				this.rowDataRef.push({
					id: this.refId,					
					file: file,				
		
				});
			}}

		}

		else if(this.dependencyType == "TopicMapOutputFacilityDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].docName;

				this.rowDataRef.push({
					id: this.refId,					
					file: file,				
		
				});
			}}

		}
		else if(this.dependencyType == "TopicMapOutputDependency"){
			if(!this.hasList){				
				this.rowDataRef.push({
					id: this.refId,
					file: this.file,
		
				});
				
			} else {
			for(let i = 0; i < this.referencesNew.length; i++){
				file = this.referencesNew[i].docName;

				this.rowDataRef.push({
					id: this.refId,					
					file: file,				
		
				});
			}}

		}
		path = "";
		file = "";
		refcount = "";
		tag = "";
		tagAttr = "";
		iceTopicName = "";
		iceTopicContentName = "";

	}


	private setDataToTable(payload : any){


		this.filteredData = [];
		this.rowData = [];
		if(payload.length != 0){
			if(this.viewWidgetId == 'tags_total_tag_reference'){
				for(let i = 0; i < payload.length; i++){
					let tagDesc = this.getTagDesc(payload[i][this.fieldName3]);
					this.filteredData.push({
						'fieldName1' : payload[i][this.fieldName1],
						'fieldName2' : payload[i][this.fieldName2],
						'fieldName3' : tagDesc,
						'fieldName4' : payload[i][this.fieldName4],
						'fieldName5' : payload[i][this.fieldName5],
						'fieldName6' : payload[i][this.fieldName6],
						'fieldName7' : payload[i][this.fieldName7],
						'fieldName8' : payload[i][this.fieldName8],
						'fieldName9' : payload[i][this.fieldName9],
						'fieldName10' : payload[i][this.fieldName10],
						'fieldName11' : payload[i][this.fieldName11],
						'fieldName12' : payload[i][this.fieldName12],
						'fieldName13' : payload[i][this.fieldName13]
					});		
				}
			}
			else if(this.viewWidgetId == 'statements_total_reference'){
				for(let i = 0; i < payload.length; i++){
					let statcCat = this.getStatementCategory(payload[i][this.fieldName3]);
					this.filteredData.push({
						'fieldName1' : payload[i][this.fieldName1],
						'fieldName2' : payload[i][this.fieldName2],
						'fieldName3' : statcCat,
						'fieldName4' : payload[i][this.fieldName4],
						'fieldName5' : payload[i][this.fieldName5],
						'fieldName6' : payload[i][this.fieldName6],
						'fieldName7' : payload[i][this.fieldName7],
						'fieldName8' : payload[i][this.fieldName8],
						'fieldName9' : payload[i][this.fieldName9],
						'fieldName10' : payload[i][this.fieldName10],
						'fieldName11' : payload[i][this.fieldName11],
						dependencies : "dependencies:" + payload[i]["id"]
					});
				}
			}
			else if(this.NoOfCol == 16){
				for(let i = 0; i < payload.length; i++){
					this.filteredData.push({
						'fieldName1' : payload[i][this.fieldName1],
						'fieldName2' : payload[i][this.fieldName2],
						'fieldName3' : payload[i][this.fieldName3],
						'fieldName4' : payload[i][this.fieldName4],
						'fieldName5' : payload[i][this.fieldName5],
						'fieldName6' : payload[i][this.fieldName6],
						'fieldName7' : payload[i][this.fieldName7],
						'fieldName8' : payload[i][this.fieldName8],
						'fieldName9' : payload[i][this.fieldName9],
						'fieldName10' : payload[i][this.fieldName10],
						'fieldName11' : payload[i][this.fieldName11],
						'fieldName12' : payload[i][this.fieldName12],
						'fieldName13' : payload[i][this.fieldName13],
						'fieldName14' : payload[i][this.fieldName14],
						'fieldName15' : payload[i][this.fieldName15],
						'fieldName16' : payload[i][this.fieldName16]
					});
				}
			}
			else if(this.NoOfCol == 13 && this.viewWidgetId != 'statements_total_reference'){
				for(let i = 0; i < payload.length; i++){
					this.filteredData.push({
						'fieldName1' : payload[i][this.fieldName1],
						'fieldName2' : payload[i][this.fieldName2],
						'fieldName3' : payload[i][this.fieldName3],
						'fieldName4' : payload[i][this.fieldName4],
						'fieldName5' : payload[i][this.fieldName5],
						'fieldName6' : payload[i][this.fieldName6],
						'fieldName7' : payload[i][this.fieldName7],
						'fieldName8' : payload[i][this.fieldName8],
						'fieldName9' : payload[i][this.fieldName9],
						'fieldName10' : payload[i][this.fieldName10],
						'fieldName11' : payload[i][this.fieldName11],
						'fieldName12' : payload[i][this.fieldName12],
						'fieldName13' : payload[i][this.fieldName13]
					});
				}
			}
			else if(this.NoOfCol == 14){
				for(let i = 0; i < payload.length; i++){
					this.filteredData.push({
						'fieldName1' : payload[i][this.fieldName1],
						'fieldName2' : payload[i][this.fieldName2],
						'fieldName3' : payload[i][this.fieldName3],
						'fieldName4' : payload[i][this.fieldName4],
						'fieldName5' : payload[i][this.fieldName5],
						'fieldName6' : payload[i][this.fieldName6],
						'fieldName7' : payload[i][this.fieldName7],
						'fieldName8' : payload[i][this.fieldName8],
						'fieldName9' : payload[i][this.fieldName9],
						'fieldName10' : payload[i][this.fieldName10],
						'fieldName11' : payload[i][this.fieldName11],
						'fieldName12' : payload[i][this.fieldName12],
						'fieldName13' : payload[i][this.fieldName13],
						'fieldName14' : payload[i][this.fieldName14]
					});
				}
			}
			else if(this.NoOfCol == 15){
				for(let i = 0; i < payload.length; i++){
					this.filteredData.push({
						'fieldName1' : payload[i][this.fieldName1],
						'fieldName2' : payload[i][this.fieldName2],
						'fieldName3' : payload[i][this.fieldName3],
						'fieldName4' : payload[i][this.fieldName4],
						'fieldName5' : payload[i][this.fieldName5],
						'fieldName6' : payload[i][this.fieldName6],
						'fieldName7' : payload[i][this.fieldName7],
						'fieldName8' : payload[i][this.fieldName8],
						'fieldName9' : payload[i][this.fieldName9],
						'fieldName10' : payload[i][this.fieldName10],
						'fieldName11' : payload[i][this.fieldName11],
						'fieldName12' : payload[i][this.fieldName12],
						'fieldName13' : payload[i][this.fieldName13],
						'fieldName14' : payload[i][this.fieldName14],
						'fieldName15' : payload[i][this.fieldName15]
						
					});
				}
			}
			else if(this.NoOfCol == 17 && this.viewWidgetId == 'tags_total_tag_reference_ref_view'){
				for(let i = 0; i < payload.length; i++){
					let tagDesc = this.getTagDesc(payload[i][this.fieldName3]);
					this.filteredData.push({
						'fieldName1' : payload[i][this.fieldName1],
						'fieldName2' : payload[i][this.fieldName2],
						'fieldName3' : tagDesc,
						'fieldName4' : payload[i][this.fieldName4],
						'fieldName5' : payload[i][this.fieldName5],
						'fieldName6' : payload[i][this.fieldName6],
						'fieldName7' : payload[i][this.fieldName7],
						'fieldName8' : payload[i][this.fieldName8],
						'fieldName9' : payload[i][this.fieldName9],
						'fieldName10' : payload[i][this.fieldName10],
						'fieldName11' : payload[i][this.fieldName11],
						'fieldName12' : payload[i][this.fieldName12],
						'fieldName13' : payload[i][this.fieldName13],
						'fieldName14' : payload[i][this.fieldName14],
						'fieldName15' : payload[i][this.fieldName15],
						'fieldName16' : payload[i][this.fieldName16],
						'fieldName17' : payload[i][this.fieldName17]
					});
				}
			}
			else if(this.NoOfCol == 20 && this.viewWidgetId == 'system_total_reference'){
				for(let i = 0; i < payload.length; i++){		
					this.filteredData.push({
						'fieldName1' : payload[i][this.fieldName1],
						'fieldName2' : payload[i][this.fieldName2],
						'fieldName3' : payload[i][this.fieldName3],
						'fieldName4' : payload[i][this.fieldName4],
						'fieldName5' : payload[i][this.fieldName5],
						'fieldName6' : payload[i][this.fieldName6],
						'fieldName7' : payload[i][this.fieldName7],
						'fieldName8' : payload[i][this.fieldName8],
						'fieldName9' : payload[i][this.fieldName9],
						'fieldName10' : payload[i][this.fieldName10],
						'fieldName11' : payload[i][this.fieldName11],
						'fieldName12' : payload[i][this.fieldName12],
						'fieldName13' : payload[i][this.fieldName13],
						'fieldName14' : payload[i][this.fieldName14],
						'fieldName15' : payload[i][this.fieldName15],
						'fieldName16' : payload[i][this.fieldName16],
						'fieldName17' : payload[i][this.fieldName17],
						'fieldName18' : payload[i][this.fieldName18],
						'fieldName19' : payload[i][this.fieldName19],
						'fieldName20' : payload[i][this.fieldName20]
					});
				}
			}
			else if(this.NoOfCol == 17 && this.viewWidgetId == 'component_total_reference'){
				for(let i = 0; i < payload.length; i++){		
					this.filteredData.push({
						'fieldName1' : payload[i][this.fieldName1],
						'fieldName2' : payload[i][this.fieldName2],
						'fieldName3' : payload[i][this.fieldName3],
						'fieldName4' : payload[i][this.fieldName4],
						'fieldName5' : payload[i][this.fieldName5],
						'fieldName6' : payload[i][this.fieldName6],
						'fieldName7' : payload[i][this.fieldName7],
						'fieldName8' : payload[i][this.fieldName8],
						'fieldName9' : payload[i][this.fieldName9],
						'fieldName10' : payload[i][this.fieldName10],
						'fieldName11' : payload[i][this.fieldName11],
						'fieldName12' : payload[i][this.fieldName12],
						'fieldName13' : payload[i][this.fieldName13],
						'fieldName14' : payload[i][this.fieldName14],
						'fieldName15' : payload[i][this.fieldName15],
						'fieldName16' : payload[i][this.fieldName16],
						'fieldName17' : payload[i][this.fieldName17]
					});
				}
			}
			else if(this.NoOfCol == 20 && this.viewWidgetId == 'document_total_reference'){
				for(let i = 0; i < payload.length; i++){		
					this.filteredData.push({
						'fieldName1' : payload[i][this.fieldName1],
						'fieldName2' : payload[i][this.fieldName2],
						'fieldName3' : payload[i][this.fieldName3],
						'fieldName4' : payload[i][this.fieldName4],
						'fieldName5' : payload[i][this.fieldName5],
						'fieldName6' : payload[i][this.fieldName6],
						'fieldName7' : payload[i][this.fieldName7],
						'fieldName8' : payload[i][this.fieldName8],
						'fieldName9' : payload[i][this.fieldName9],
						'fieldName10' : payload[i][this.fieldName10],
						'fieldName11' : payload[i][this.fieldName11],
						'fieldName12' : payload[i][this.fieldName12],
						'fieldName13' : payload[i][this.fieldName13],
						'fieldName14' : payload[i][this.fieldName14],
						'fieldName15' : payload[i][this.fieldName15],
						'fieldName16' : payload[i][this.fieldName16],
						'fieldName17' : payload[i][this.fieldName17],
						'fieldName18' : payload[i][this.fieldName18],
						'fieldName19' : payload[i][this.fieldName19],
						'fieldName20' : payload[i][this.fieldName20]
					});
				}
			}
			else if(this.NoOfCol == 27){
				for(let i = 0; i < payload.length; i++){		
					this.filteredData.push({
						'fieldName1' : payload[i][this.fieldName1],
						'fieldName2' : payload[i][this.fieldName2],
						'fieldName3' : payload[i][this.fieldName3],
						'fieldName4' : payload[i][this.fieldName4],
						'fieldName5' : payload[i][this.fieldName5],
						'fieldName6' : payload[i][this.fieldName6],
						'fieldName7' : payload[i][this.fieldName7],
						'fieldName8' : payload[i][this.fieldName8],
						'fieldName9' : payload[i][this.fieldName9],
						'fieldName10' : payload[i][this.fieldName10],
						'fieldName11' : payload[i][this.fieldName11],
						'fieldName12' : payload[i][this.fieldName12],
						'fieldName13' : payload[i][this.fieldName13],
						'fieldName14' : payload[i][this.fieldName14],
						'fieldName15' : payload[i][this.fieldName15],
						'fieldName16' : payload[i][this.fieldName16],
						'fieldName17' : payload[i][this.fieldName17],
						'fieldName18' : payload[i][this.fieldName18],
						'fieldName19' : payload[i][this.fieldName19],
						'fieldName20' : payload[i][this.fieldName20],
						'fieldName21' : payload[i][this.fieldName21],
						'fieldName22' : payload[i][this.fieldName22],
						'fieldName23' : payload[i][this.fieldName23],
						'fieldName24' : payload[i][this.fieldName24],
						'fieldName25' : payload[i][this.fieldName25],
						'fieldName26' : payload[i][this.fieldName26],
						'fieldName27' : payload[i][this.fieldName27]
					});
				}
			}
			else if(this.NoOfCol == 6){
				for(let i = 0; i < payload.length; i++){
					this.filteredData.push({
						'fieldName1' : payload[i][this.fieldName1],
						'fieldName2' : payload[i][this.fieldName2],
						'fieldName3' : payload[i][this.fieldName3],
						'fieldName4' : payload[i][this.fieldName4],
						'fieldName5' : payload[i][this.fieldName5],
						'fieldName6' : payload[i][this.fieldName6]
					});
				}
			}else if(this.NoOfCol == 4){
				for(let i = 0; i < payload.length; i++){
					this.filteredData.push({
						'fieldName1' : payload[i][this.fieldName1],
						'fieldName2' : payload[i][this.fieldName2],
						'fieldName3' : payload[i][this.fieldName3],
						'fieldName4' : payload[i][this.fieldName4],
					});
				}
			}else if(this.NoOfCol == 7){
				for(let i = 0; i < payload.length; i++){
					this.filteredData.push({
						'fieldName1' : payload[i][this.fieldName1],
						'fieldName2' : payload[i][this.fieldName2],
						'fieldName3' : payload[i][this.fieldName3],
						'fieldName4' : payload[i][this.fieldName4],
						'fieldName5' : payload[i][this.fieldName5],
						'fieldName6' : payload[i][this.fieldName6],
						'fieldName7' : payload[i][this.fieldName7]
					});
				}
			}else if(this.NoOfCol == 3){
				for(let i = 0; i < payload.length; i++){
					this.filteredData.push({
						'fieldName1' : payload[i][this.fieldName1],
						'fieldName2' : payload[i][this.fieldName2],
						'fieldName3' : payload[i][this.fieldName3],
					});
				}
			}else if(this.NoOfCol == 5){
				for(let i = 0; i < payload.length; i++){
					this.filteredData.push({
						'fieldName1' : payload[i][this.fieldName1],
						'fieldName2' : payload[i][this.fieldName2],
						'fieldName3' : payload[i][this.fieldName3],
						'fieldName4' : payload[i][this.fieldName4],
						'fieldName5' : payload[i][this.fieldName5]
					});
				}
			}else if(this.viewWidgetId =='topic_total_revisions' ){
				for(let i = 0; i < payload.length; i++){
					this.filteredData.push({
						'index' : i+1 ,
						'fieldName1' : payload[i][this.fieldName1],
						'fieldName2' : payload[i][this.fieldName2],
					});
				}
			}
			
			else if(this.viewWidgetId =='tags_total_authors'  ){
				for(let i = 0; i < payload.length; i++){
					this.filteredData.push({
						'index' : i+1 ,
						'fieldName1' : payload[i][this.fieldName1],
						'fieldName2' : payload[i][this.fieldName2],
						'fieldName3' : payload[i][this.fieldName3],
					});
				}
			}else{
				for(let i = 0; i < payload.length; i++){
					this.filteredData.push({
						'index' : i+1 ,
						'fieldName1' : payload[i][this.fieldName1]
					});
				}
			}
			this.rowData = this.filteredData;
			//this.gridOptions.api.resetRowHeights()
			this.gridOptions.api.sizeColumnsToFit();
			

		}else{
			//no rows to show
		}
	}
	
	
	private setPageParams(){
		console.log("this.translated_data.LEGEND.AUTHOR_FIRST_NAME= "+this.translated_data.LEGEND.AUTHOR_FIRST_NAME)
		let projectName = localStorage.getItem("selectedProjectName");
		switch (this.viewWidgetId) {
			case 'tags_total_tag_reference':
				this.fieldName1 = "id";
				this.fieldName2 = "tagNo";
				this.fieldName3 = "description";
				this.fieldName4 = "systemName";
				this.fieldName5 = "documentName";
				this.fieldName6 = "facilityName";
				this.fieldName7 = "tagNoReferenceCount";
				this.fieldName8 = "tagDescReferenceCount";
				this.fieldName9 = "tagSystemReferenceCount";
				this.fieldName10 = "tagDocumentReferenceCount";
				this.fieldName11 = "tagAttributeReferenceCount";
				this.fieldName12 = "dependencyCount";
				this.fieldName13 = "referenceCount";
				this.colName[1] = this.translated_data.REPORT_TAG_ID;
				this.colName[2] = this.translated_data.REPORT_TAG_NO;
				this.colName[3] = this.translated_data.REPORT_TAG_DESCRIPTION;
				this.colName[4] = this.translated_data.REPORT_SYSTEM_NAME;
				this.colName[5] = this.translated_data.REPORT_DOCUMENT_NAME;
				this.colName[6] = this.translated_data.REPORT_FACILITY_NAME;
				this.colName[7] = this.translated_data.REPORT_TAG_NO_COUNT;
				this.colName[8] = this.translated_data.REPORT_TAG_DESC_COUNT;
				this.colName[9] = this.translated_data.REPORT_TAG_SYSTEM_COUNT;
				this.colName[10] = this.translated_data.REPORT_TAG_DOCUMENT_COUNT;
				this.colName[11] = this.translated_data.REPORT_TAG_ATTRIBUTE_COUNT;
				this.colName[12] = this.translated_data.REPORT_DEPENTENCY_COUNT;
				this.colName[13] = this.translated_data.REPORT_REFERENCE_COUNT;
				
				this.legend[0] = this.translated_data.LEGEND_TAG_ID;
				this.legend[1] = this.translated_data.LEGEND_TAG_NO;
				this.legend[2] = this.translated_data.LEGEND_TAG_DESCRIPTION;
				this.legend[3] = this.translated_data.LEGEND_SYSTEM_NAME;
				this.legend[4] = this.translated_data.LEGEND_DOCUMENT_NAME;
				this.legend[5] = this.translated_data.LEGEND_FACILITY_NAME;
				this.NoOfCol = 13;
				if(this.used){
					this.title = this.translated_data.REPORT_USED_TAG_LIST + " - " + projectName;
					this.legendTitle = this.translated_data.LEGEND_USED_TAG_TITLE + projectName
					// This report shows the tags in the project and the number of times a tag is referenced by topics and procedures.
				} else{
					this.title = this.translated_data.REPORT_TOTAL_TAG_LIST + " - " + projectName;
					this.legendTitle = this.translated_data.LEGEND_TAG_TITLE + projectName
				}
				break;
				case 'tags_total_tag_reference_ref_view':
					this.fieldName1 = "id";
					this.fieldName2 = "tagNo";
					this.fieldName3 = "description";
					this.fieldName4 = "topicTagReferenceCount";
					this.fieldName5 = "procedureTagReferenceCount";
					this.fieldName6 = "iceCourseReferenceCount";
					this.fieldName7 = "iceTopicReferenceCount";
					this.fieldName8 = "iceTopicContentTagReferenceCount";
					this.fieldName9 = "assetParentTagReferenceCount";
					this.fieldName10 = "assetAttributeTagReferenceCount";
					this.fieldName11 = "checksheetTagReferenceCount";
					this.fieldName12 = "locationParentTagReferenceCount";
					this.fieldName13 = "workitemTagReferenceCount";
					this.fieldName14 = "punchlistTagReferenceCount";
					this.fieldName15 = "irnTagReferenceCount";
					this.fieldName16 = "dependencyCount";
					this.fieldName17 = "referenceCount";
					this.colName[1] = this.translated_data.REPORT_TAG_ID;
					this.colName[2] = this.translated_data.REPORT_TAG_NO;
					this.colName[3] = this.translated_data.REPORT_TAG_DESCRIPTION;
					this.colName[4] = this.translated_data.REPORT_TOPIC_COUNT;
					this.colName[5] = this.translated_data.REPORT_PROCEDURE_COUNT;
					this.colName[6] = this.translated_data.REPORT_ICE_COURSE_COUNT;
					this.colName[7] = this.translated_data.REPORT_ICE_TOPIC_COUNT;
					this.colName[8] = this.translated_data.REPORT_ICE_TOPIC_CONTENT_COUNT;
					this.colName[9] = this.translated_data.REPORT_ASSET_PARENT_COUNT;
					this.colName[10] = this.translated_data.REPORT_ASSET_ATTRIBUTE_COUNT;
					this.colName[11] = this.translated_data.REPORT_CHECKSHEET_COUNT;
					this.colName[12] = this.translated_data.REPORT_TAG_COUNT;
					this.colName[13] = this.translated_data.REPORT_WORKITEM_COUNT;
					this.colName[14] = this.translated_data.REPORT_PUNCHLIST_COUNT;
					this.colName[15] = this.translated_data.REPORT_IRN_COUNT;
					this.colName[16] = this.translated_data.REPORT_DEPENDENCY_COUNT;
					this.colName[17] = this.translated_data.REPORT_REFERENCE_COUNT;
					this.NoOfCol = 17;
					
					this.title = this.translated_data.REPORT_USED_TAG_LIST_REF_VIEW + " - " + projectName;

					
					break;

			case 'tags_total_authors':
				this.fieldName1 = "id";
				this.fieldName2 = "firstName";
				this.fieldName3 = "lastName";
				this.colName1 = this.translated_data.REORT_USER_ID
				this.colName2 = this.translated_data.REPORT_FIRST_NAME;
				this.colName3 =  this.translated_data.REPORT_LAST_NAME;
				

				this.legend[0] = this.translated_data.LEGEND_TAG_ID;
				this.legend[1] = this.translated_data.LEGEND_TAG_NO;

				this.title = this.translated_data.REPORT_AUTHORS + " - " + projectName;

				break;
			case 'statements_word_count':
				//removed from reports
				break;
			case 'statements_total_reference':
				this.fieldName1 = "id";
				this.fieldName2 = "title";
				this.fieldName3 = "content";
				this.fieldName4 = "categoryName";				
				this.fieldName5 = "topicrefCount";
				this.fieldName6 = "iceCourseReferenceCount";
				this.fieldName7 = "iceTopicReferenceCount";
				this.fieldName8 = "iceTopicContentrefCount";
				this.fieldName9 = "procedurerefCount";
				this.fieldName10 = "dependencyCount";
				this.fieldName11 = "referenceCount";
				this.colName[1] = this.translated_data.REPORT_STATEMENT_ID;
				this.colName[2] = this.translated_data.REPORT_STATEMENT_TITLE;
				this.colName[3] = this.translated_data.REPORT_STATEMENT_CONTENT;
				this.colName[4] = this.translated_data.REPORT_CATEGORY_NAME;
				this.colName[5] = this.translated_data.REPORT_TOPIC_COUNT;
				this.colName[6] = this.translated_data.REPORT_ICE_COURSE_COUNT;
				this.colName[7] = this.translated_data.REPORT_ICE_TOPIC_COUNT;
				this.colName[8] = this.translated_data.REPORT_ICE_TOPIC_CONTENT_COUNT;
				this.colName[9] = this.translated_data.REPORT_PROCEDURE_COUNT;
				this.colName[10] = this.translated_data.REPORT_DEPENTENCY_COUNT;
				this.colName[11] = this.translated_data.REPORT_REFERENCE_COUNT;
				this.NoOfCol = 11;
				if(this.used){
					this.title = this.translated_data.REPORT_USED_STATEMENT_LIST + " - " + projectName;
				} else{
					this.title = this.translated_data.REPORT_TOTAL_STATEMENT_LIST + " - " + projectName;
				}
				break;
			case 'topic_total_topics':
				this.fieldName1 = "id";
				this.fieldName2 = "topicName";
				this.fieldName3 = "topicType";
				this.fieldName4 = "title";
				this.fieldName5 = "folderPath";
				this.colName[1] = this.translated_data.REPORT_TOPIC_ID;
				this.colName[2] = this.translated_data.REPORT_TOPIC_NAME;
				this.colName[3] = this.translated_data.REPORT_TOPIC_TYPE;
				this.colName[4] = this.translated_data.REPORT_TOPIC_TITLE;
				this.colName[5] = this.translated_data.REPORT_FOLDER_PATH;
				this.title = this.translated_data.REPORT_TOPIC_LIST + " - " + projectName;
				this.NoOfCol = 5;
				break;
			case 'document_total_reference':
				this.fieldName1 = "id";
				this.fieldName2 = "documentName";
				this.fieldName3 = "documentType";
				this.fieldName4 = "facilityName";
				this.fieldName5 = "revision";
				this.fieldName6 = "altDocNo";
				this.fieldName7 = "attachedFileName";
				this.fieldName8 = "topicReferenceCount";
				this.fieldName9 = "topicMapReferenceCount";
				this.fieldName10 = "imageReferenceCount";
				this.fieldName11 = "contentItemReferenceCount";
				this.fieldName12 = "videoReferenceCount";
				this.fieldName13 = "threedmodelReferenceCount";
				this.fieldName14 = "procedureCount";
				this.fieldName15 = "tagReferenceCount";
				this.fieldName16 = "iceCourseReferenceCount";
				this.fieldName17 = "iceTopicReferenceCount";
				this.fieldName18 = "iceTopicContentSystemReferenceCount";
				this.fieldName19 = "dependencyCount";
				this.fieldName20 = "referenceCount";
				this.colName[1] = this.translated_data.REPORT_DOCUMENT_ID;
				this.colName[2] = this.translated_data.REPORT_DOCUMENT_NAME;
				this.colName[3] = this.translated_data.REPORT_DOCUMENT_TYPE;
				this.colName[4] = this.translated_data.REPORT_FACILITY_NAME;
				this.colName[5] = this.translated_data.REPORT_DOCUMENT_REVISION;
				this.colName[6] = this.translated_data.REPORT_DOCUMENT_ALTDOC_NO;
				this.colName[7] = this.translated_data.REPORT_DOCUMENT_PDF_FILE;
				this.colName[8] = this.translated_data.REPORT_TOPIC_COUNT;
				this.colName[9] = this.translated_data.REPORT_TOPICMAP_COUNT;
				this.colName[10] = this.translated_data.REPORT_IMAGE_COUNT;
				this.colName[11] = this.translated_data.REPORT_FILE_COUNT;
				this.colName[12] = this.translated_data.REPORT_VIDEO_COUNT;
				this.colName[13] = this.translated_data.REPORT_THREEDMODEL_COUNT;
				this.colName[14] = this.translated_data.REPORT_PROCEDURE_COUNT;
				this.colName[15] = this.translated_data.REPORT_TAG_COUNT;
				this.colName[16] = this.translated_data.REPORT_ICE_COURSE_COUNT;
				this.colName[17] = this.translated_data.REPORT_ICE_TOPIC_COUNT;
				this.colName[18] = this.translated_data.REPORT_ICE_TOPIC_CONTENT_COUNT;
				this.colName[19] = this.translated_data.REPORT_DEPENDENCY_COUNT;
				this.colName[20] = this.translated_data.REPORT_REFERENCE_COUNT;
				this.NoOfCol = 20;
				if(this.used){
					this.title = this.translated_data.REPORT_USED_DOCUMENT_LIST + " - " + projectName;
				} else{
					this.title = this.translated_data.REPORT_TOTAL_DOCUMENT_LIST + " - " + projectName;
				}
				break;
			case 'topic_total_revisions':
				this.fieldName1 = "cmRevisionId"
				this.fieldName2 = "itemName";
				this.colName1 = this.translated_data.REPORT_CMREVISION_ID
				this.colName2 = this.translated_data.REPORT_TOPIC_NAME
				
				this.title = this.translated_data.REPORT_TOPIC_REVISION_LIST + " - " + projectName;
				
				break;
			case 'document_total_types':
				this.fieldName1 = "documentTypeId";
				this.fieldName2 = "documentType";
				this.fieldName3 = "path";
				this.colName[1] = this.translated_data.REPORT_DOCUMENT_TYPE_ID;
				this.colName[2] = this.translated_data.REPORT_DOCUMENT_TYPE;
				this.colName[3] = this.translated_data.REPORT_DOCUMENT_TYPE_PATH;
				this.NoOfCol = 3;
				if(this.used){
					this.title = this.translated_data.REPORT_USED_DOCUMENT_TYPE_LIST + " - " + projectName;
				} else{
					//document types are global
					this.title = this.translated_data.REPORT_TOTAL_DOCUMENT_TYPE_LIST; //+ " - " + projectName;
				}
				break;
			case 'component_total_reference':
				this.fieldName1 = "id";
				this.fieldName2 = "componentName";
				this.fieldName3 = "componentValue";
				this.fieldName4 = "categoryName";
				this.fieldName5 = "topicReferenceCount";
				this.fieldName6 = "topicMapReferenceCount";
				this.fieldName7 = "imageReferenceCount";
				this.fieldName8 = "contentItemReferenceCount";
				this.fieldName9 = "videoReferenceCount";
				this.fieldName10 = "threedmodelReferenceCount";
				this.fieldName11 = "procedureReferenceCount";
				this.fieldName12 = "tagReferenceCount";
				this.fieldName13 = "iceCourseReferenceCount";
				this.fieldName14 = "iceTopicReferenceCount";
				this.fieldName15 = "iceTopicContentSystemReferenceCount";
				this.fieldName16 = "dependencyCount";
				this.fieldName17 = "referenceCount";
				this.colName[1] = this.translated_data.REPORT_COMPONENT_ID;
				this.colName[2] = this.translated_data.REPORT_COMPONENT_NAME;
				this.colName[3] = this.translated_data.REPORT_COMPONENT_VALUE;
				this.colName[4] = this.translated_data.REPORT_CATEGORY_NAME;
				this.colName[5] = this.translated_data.REPORT_TOPIC_COUNT;
				this.colName[6] = this.translated_data.REPORT_TOPICMAP_COUNT;
				this.colName[7] = this.translated_data.REPORT_IMAGE_COUNT;
				this.colName[8] = this.translated_data.REPORT_FILE_COUNT;
				this.colName[9] = this.translated_data.REPORT_VIDEO_COUNT;
				this.colName[10] = this.translated_data.REPORT_THREEDMODEL_COUNT;
				this.colName[11] = this.translated_data.REPORT_PROCEDURE_COUNT;
				this.colName[12] = this.translated_data.REPORT_TAG_COUNT;
				this.colName[13] = this.translated_data.REPORT_ICE_COURSE_COUNT;
				this.colName[14] = this.translated_data.REPORT_ICE_TOPIC_COUNT;
				this.colName[15] = this.translated_data.REPORT_ICE_TOPIC_CONTENT_COUNT;
				this.colName[16] = this.translated_data.REPORT_DEPENDENCY_COUNT;
				this.colName[17] = this.translated_data.REPORT_REFERENCE_COUNT;
				this.NoOfCol = 17;
				if(this.used){
					this.title = this.translated_data.REPORT_USED_COMPONENT_LIST + " - " + projectName;
				} else{
					this.title = this.translated_data.REPORT_TOTAL_COMPONENT_LIST + " - " + projectName;
				}
				break;
			case 'system_total_reference':
				this.fieldName1 = "id";
				this.fieldName2 = "systemNo";
				this.fieldName3 = "systemName";
				this.fieldName4 = "facilityName";
				this.fieldName5 = "topicReferenceCount";
				this.fieldName6 = "topicMapReferenceCount";
				this.fieldName7 = "imageReferenceCount";
				this.fieldName8 = "contentItemReferenceCount";
				this.fieldName9 = "videoReferenceCount";
				this.fieldName10 = "threedmodelReferenceCount";
				this.fieldName11 = "procedureCount";
				this.fieldName12 = "tagReferenceCount";
				this.fieldName13 = "checksheetReferenceCount";
				this.fieldName14 = "iceCourseReferenceCount";
				this.fieldName15 = "iceTopicReferenceCount";
				this.fieldName16 = "iceTopicContentSystemReferenceCount";
				this.fieldName17 = "systemReferenceCount";
				this.fieldName18 = "punchlistReferenceCount";
				this.fieldName19 = "mccReferenceCount";
				this.fieldName20 = "sccReferenceCount";
				this.fieldName21 = "cccReferenceCount";
				this.fieldName22 = "stnReferenceCount";
				this.fieldName23 = "shcReferenceCount";
				this.fieldName24 = "scsReferenceCount";
				this.fieldName25 = "topicmapOutputReferenceCount";
				this.fieldName26 = "dependencyCount";
				this.fieldName27 = "referenceCount";
				this.colName[1] = this.translated_data.REPORT_SYSTEM_ID;
				this.colName[2] = this.translated_data.REPORT_SYSTEM_NO;
				this.colName[3] = this.translated_data.REPORT_SYSTEM_NAME;
				this.colName[4] = this.translated_data.REPORT_FACILITY_NAME;
				this.colName[5] = this.translated_data.REPORT_TOPIC_COUNT;
				this.colName[6] = this.translated_data.REPORT_TOPICMAP_COUNT;
				this.colName[7] = this.translated_data.REPORT_IMAGE_COUNT;
				this.colName[8] = this.translated_data.REPORT_FILE_COUNT;
				this.colName[9] = this.translated_data.REPORT_VIDEO_COUNT;
				this.colName[10] = this.translated_data.REPORT_THREEDMODEL_COUNT;
				this.colName[11] = this.translated_data.REPORT_PROCEDURE_COUNT;
				this.colName[12] = this.translated_data.REPORT_TAG_COUNT;
				this.colName[13] = this.translated_data.REPORT_CHECKSHEET_COUNT;
				this.colName[14] = this.translated_data.REPORT_ICE_COURSE_COUNT;
				this.colName[15] = this.translated_data.REPORT_ICE_TOPIC_COUNT;
				this.colName[16] = this.translated_data.REPORT_ICE_TOPIC_CONTENT_COUNT;
				this.colName[17] = this.translated_data.REPORT_SYSTEM_COUNT;
				this.colName[18] = this.translated_data.REPORT_PUNCHLIST_COUNT;
				this.colName[19] = this.translated_data.REPORT_MCC_COUNT;
				this.colName[20] = this.translated_data.REPORT_SCC_COUNT;
				this.colName[21] = this.translated_data.REPORT_CCC_COUNT;
				this.colName[22] = this.translated_data.REPORT_STN_COUNT;
				this.colName[23] = this.translated_data.REPORT_SHC_COUNT;
				this.colName[24] = this.translated_data.REPORT_SCS_COUNT;
				this.colName[25] = this.translated_data.REPORT_TOPICMAPOUTPUT_COUNT;
				this.colName[26] = this.translated_data.REPORT_DEPENDENCY_COUNT;
				this.colName[27] = this.translated_data.REPORT_REFERENCE_COUNT;

				this.NoOfCol = 27;
				if(this.used){
					this.title = this.translated_data.REPORT_USED_SYSTEM_LIST + " - " + projectName;
				} else{
					this.title = this.translated_data.REPORT_TOTAL_SYSTEM_LIST + " - " + projectName;
				}
				break;

				case 'system_total_reference_sys_view':
				this.fieldName1 = "id";
				this.fieldName2 = "systemNo";
				this.fieldName3 = "systemName";
				this.fieldName4 = "systemNoReferenceCount";
				this.fieldName5 = "systemNameReferenceCount";
				this.fieldName6 = "dependencyCount";
				this.fieldName7 = "referenceCount";
				this.colName[1] = this.translated_data.REPORT_SYSTEM_ID;
				this.colName[2] = this.translated_data.REPORT_SYSTEM_NO;
				this.colName[3] = this.translated_data.REPORT_SYSTEM_NAME;
				this.colName[4] = this.translated_data.REPORT_SYSTEM_NO_COUNT;
				this.colName[5] = this.translated_data.REPORT_SYSTEM_NAME_COUNT;
				this.colName[6] = this.translated_data.REPORT_DEPENDENCY_COUNT;
				this.colName[7] = this.translated_data.REPORT_REFERENCE_COUNT;
				this.NoOfCol = 7;
				
				this.title = this.translated_data.REPORT_USED_SYSTEM_LIST_SYSTEM_VIEW + " - " + projectName;
				
				break;


			case 'facility_total_reference':
				this.fieldName1 = "id";
				this.fieldName2 = "facilityName";
				this.fieldName3 = "emailAddress";
				this.fieldName4 = "secondaryEmailAddress";
				this.fieldName5 = "topicrefCount";;
				this.fieldName6 = "procedurerefCount";
				this.fieldName7 = "tagrefCount";
				this.fieldName8 = "systemrefCount";
				this.fieldName9 = "documentrefCount";
				this.fieldName10 = "iceCourseReferenceCount";
				this.fieldName11 = "iceTopicReferenceCount";
				this.fieldName12 = "iceTopicContentrefCount";
				this.fieldName13 = "topicmapOutputReferenceCount";
				this.fieldName14 = "dependencyCount";
				this.fieldName15 = "referenceCount";
				this.colName[1] = this.translated_data.REPORT_FACILITY_ID;
				this.colName[2] = this.translated_data.REPORT_FACILITY_NAME;
				this.colName[3] = this.translated_data.REPORT_EMAIL_ADDRESS;
				this.colName[4] = this.translated_data.REPORT_SECONDARY_EMAIL_ADDRESS;
				this.colName[5] = this.translated_data.REPORT_TOPIC_COUNT;
				this.colName[6] = this.translated_data.REPORT_PROCEDURE_COUNT;
				this.colName[7] = this.translated_data.REPORT_TAG_COUNT;
				this.colName[8] = this.translated_data.REPORT_SYSTEM_COUNT;
				this.colName[9] = this.translated_data.REPORT_DOCUMENT_COUNT;
				this.colName[10] = this.translated_data.REPORT_ICE_COURSE_COUNT;
				this.colName[11] = this.translated_data.REPORT_ICE_TOPIC_COUNT;
				this.colName[12] = this.translated_data.REPORT_ICE_TOPIC_CONTENT_COUNT;
				this.colName[13] = this.translated_data.REPORT_TOPICMAPOUTPUT_COUNT;
				this.colName[14] = this.translated_data.REPORT_DEPENTENCY_COUNT;
				this.colName[15] = this.translated_data.REPORT_REFERENCE_COUNT;
				this.NoOfCol = 15;
				if(this.used){
					this.title = this.translated_data.REPORT_USED_FACILITY_LIST + " - " + projectName;
				} else{
					this.title = this.translated_data.REPORT_TOTAL_FACILITY_LIST + " - " + projectName;
				}
				break;
			case 'procedure_Graphic_reference':
				this.fieldName1 = "procedureId";
				this.fieldName2 = "procedureName";
				this.fieldName3 = "path";
				this.fieldName4 = "graphicCount";
				this.colName[1] = this.translated_data.REPORT_PROCEDURE_ID;
				this.colName[2] = this.translated_data.REPORT_PROCEDURE_NAME;
				this.colName[3] =  this.translated_data.REPORT_FOLDER_PATH;
				this.colName[4] =  this.translated_data.REPORT_PROCEDURE_GRAPHIC_COUNT;
				this.NoOfCol = 4;
				this.title = this.translated_data.REPORT_PROCEDURE_GRAPHIC_REPORT + " - " + projectName;
				break;
			case 'total_course':
				this.fieldName1 = "id";
				this.fieldName2 = "fileName";
				this.fieldName3 = "courseName";
				this.fieldName4 = "courseDescription";
				//this.fieldName5 = "folderName";
				this.fieldName5 = "path";
				this.colName[1] = this.translated_data.REPORT_COURSE_ID;
				this.colName[2] = this.translated_data.REPORT_COURSE_NAME;
				this.colName[3] = this.translated_data.REPORT_COURSE_TITLE;
				this.colName[4] = this.translated_data.REPORT_COURSE_DESCRIPTION;
				//this.colName[5] = this.translated_data.REPORT_FOLDER_NAME;
				this.colName[5] = this.translated_data.REPORT_COURSE_PATH;
				this.NoOfCol = 5;
				this.title = this.translated_data.TOTAL_COURSE_LIST + " - " + projectName;
				break;
			case 'topic_count':
				this.fieldName1 = "topicId";
				this.fieldName2 = "topicName";
				this.fieldName3 = "topicDescription";
				this.fieldName4 = "topicFileName";
				this.fieldName5 = "topicFolderPath";
				this.colName[1] = this.translated_data.REPORT_COURSE_TOPIC_ID;
				this.colName[2] = this.translated_data.REPORT_COURSE_TOPIC_NAME;
				this.colName[3] = this.translated_data.REPORT_COURSE_TOPIC_DESCRIPTION;
				this.colName[4] = this.translated_data.REPORT_COURSE_NAME;
				this.colName[5] = this.translated_data.REPORT_COURSE_PATH;
				this.NoOfCol = 5;
				this.title = this.translated_data.REPORT_TOPIC_LIST + " - " + projectName;
				break;
			case 'topic_content_count':
				this.fieldName1 = "topicContentId";
				this.fieldName2 = "topicContentDescription";
				this.fieldName3 = "topicContentFileName";
				this.fieldName4 = "topicContentFolderPath";
				this.colName[1] = this.translated_data.REPORT_TOPIC_CONTENT_ID;
				this.colName[2] = this.translated_data.REPORT_TOPIC_CONTENT_DESCRIPTION;
				this.colName[3] = this.translated_data.REPORT_COURSE_NAME;
				this.colName[4] = this.translated_data.REPORT_COURSE_PATH;
				this.NoOfCol = 4;
				this.title = this.translated_data.REPORT_TOPIC_CONTENT_LIST + " - " + projectName;
			    break;
			case 'learning_check_count':
				this.fieldName1 = "learningCheckId";
				this.fieldName2 = "learningCheckDescription";
				this.fieldName3 = "learningCheckCategory";
				this.fieldName4 = "learningCheckFileName";
				this.fieldName5 = "learningCheckFolderPath";
				this.colName[1] = this.translated_data.REPORT_LEARNING_CHECK_ID;
				this.colName[2] = this.translated_data.REPORT_LEARNING_CHECK_DESCRIPTION;
				this.colName[3] = this.translated_data.REPORT_LEARNING_CHECK_CATEGORY;
				this.colName[4] = this.translated_data.REPORT_COURSE_NAME;
				this.colName[5] = this.translated_data.REPORT_COURSE_PATH;
				this.NoOfCol = 5;
				this.title = this.translated_data.REPORT_LEARNING_CHECK_LIST + " - " + projectName;
				break;
			case 'game_count':
				this.fieldName1 = "gameId";
				this.fieldName2 = "gameName";
				this.fieldName3 = "gameFileName";
				this.fieldName4 = "gameFolderPath";			
				this.colName[1] = this.translated_data.REPORT_GAME_ID;
				this.colName[2] = this.translated_data.REPORT_GAME_NAME;				
				this.colName[3] = this.translated_data.REPORT_COURSE_NAME;
				this.colName[4] = this.translated_data.REPORT_COURSE_PATH;
				this.NoOfCol = 4;
				this.title = this.translated_data.REPORT_GAME_LIST + " - " + projectName;
				break;
			case 'total_3d_model':
				this.fieldName1 = "id";
				this.fieldName2 = "title";
				this.fieldName3 = "documentType";
				this.fieldName4 = "folderPath";			
				this.colName[1] = this.translated_data.REPORT_3D_MODEL_ID;
				this.colName[2] = this.translated_data.REPORT_3D_MODEL_TITLE;				
				this.colName[3] = this.translated_data.REPORT_DOCUMENT_TYPE;
				this.colName[4] = this.translated_data.REPORT_FOLDER_PATH;
				this.NoOfCol = 4;
				this.title = this.translated_data.REPORT_3D_MODEL_LIST + " - " + projectName;
				break;
			case 'total_videos':
				this.fieldName1 = "id";
				this.fieldName2 = "title";
				this.fieldName3 = "documentType";
				this.fieldName4 = "folderPath";			
				this.colName[1] = this.translated_data.REPORT_VIDEO_ID;
				this.colName[2] = this.translated_data.REPORT_VIDEO_TITLE;				
				this.colName[3] = this.translated_data.REPORT_DOCUMENT_TYPE;
				this.colName[4] = this.translated_data.REPORT_FOLDER_PATH;
				this.NoOfCol = 4;
				this.title = this.translated_data.REPORT_VIDEO_LIST + " - " + projectName;
				break;
			case 'procedure_total_reference':
				this.fieldName1 = "procedureId";
				this.fieldName2 = "procedureName";
				this.fieldName3 = "procDescription";
				this.fieldName4 = "systemName";
				this.fieldName5 = "documentType";
				this.fieldName6 = "configName";
				this.fieldName7 = "stepCount";
				this.colName[1] = this.translated_data.REPORT_PROCEDURE_ID;
				this.colName[2] = this.translated_data.REPORT_PROCEDURE_NAME;
				this.colName[3] = this.translated_data.REPORT_PROCEDURE_DESCRIPTION;
				this.colName[4] = this.translated_data.REPORT_SYSTEM_NAME;
				this.colName[5] = this.translated_data.REPORT_PROCEDURE_TYPE;
				this.colName[6] = this.translated_data.REPORT_PROCEDURE_CONFIG;
				this.colName[7] = this.translated_data.REPORT_PROCEDURE_STEP_COUNT;
				this.NoOfCol = 7;
				this.title = this.translated_data.REPORT_PROCEDURE_TOTAL_REPORT + " - " + projectName;
			default:
				break;
		}
	}



	private setColumnDefs(){

		if(this.NoOfCol != 0 && this.viewWidgetId =="tags_total_tag_reference_ref_view"){
			
			this.columnDefs = [
				{ headerName: this.colName[1], field: 'fieldName1', autoHeight: true, hide: true,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[2], field: 'fieldName2', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[3], field: 'fieldName3', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[4], field: 'fieldName4', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[5], field: 'fieldName5', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{
					headerName: "ICE Course Counts", 
					children: [
						{ headerName:this.colName[6], field: 'fieldName6', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
						{ headerName:this.colName[7], field: 'fieldName7', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
						{ headerName:this.colName[8], field: 'fieldName8', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
					] 
				},

				{
					headerName: "Asset Counts", 
					children: [
						{ headerName:this.colName[9], field: 'fieldName9', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
						{ headerName:this.colName[10], field: 'fieldName10', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
						
					] 
				},

				{
					headerName: "Commissioning Counts", 
					children: [
						{ headerName:this.colName[11], field: 'fieldName11', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
						{ headerName:this.colName[13], field: 'fieldName13', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
						{ headerName:this.colName[14], field: 'fieldName14', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
						{ headerName:this.colName[15], field: 'fieldName15', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
					] 
				},
				{ headerName: this.colName[12], field: 'fieldName12', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[16], field: 'fieldName16', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[17], field: 'fieldName17', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" }		
			
			]
		}
		else if(this.NoOfCol != 0 && this.viewWidgetId =="system_total_reference"){
			
			this.columnDefs = [
				{ headerName: this.colName[1], field: 'fieldName1', autoHeight: true, hide: true,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"},
				{ headerName: this.colName[2], field: 'fieldName2', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[3], field: 'fieldName3', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"},
				{ headerName: this.colName[4], field: 'fieldName4', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"},
				{ headerName: this.colName[5], field: 'fieldName5', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName:this.colName[6], field: 'fieldName6', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
				{ headerName:this.colName[7], field: 'fieldName7', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
				{ headerName:this.colName[8], field: 'fieldName8', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
				{ headerName:this.colName[9], field: 'fieldName9', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
				{ headerName:this.colName[10], field: 'fieldName10', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
				{ headerName:this.colName[11], field: 'fieldName11', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
				{ headerName:this.colName[12], field: 'fieldName12', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
				
				{
					headerName: "ICE Course Counts", 
					children: [
						{ headerName:this.colName[14], field: 'fieldName14', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
						{ headerName:this.colName[15], field: 'fieldName15', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
						{ headerName:this.colName[16], field: 'fieldName16', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
					] 
				},
				{
					headerName: "Commissioning Counts", 
					children: [
						{ headerName:this.colName[13], field: 'fieldName13', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
						{ headerName:this.colName[18], field: 'fieldName18', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
						{ headerName:this.colName[19], field: 'fieldName19', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
						{ headerName:this.colName[20], field: 'fieldName20', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
						{ headerName:this.colName[21], field: 'fieldName21', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
						{ headerName:this.colName[22], field: 'fieldName22', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
						{ headerName:this.colName[23], field: 'fieldName23', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
						{ headerName:this.colName[24], field: 'fieldName24', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
					] 
				},
				{ headerName: this.colName[17], field: 'fieldName17', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[25], field: 'fieldName25', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[26], field: 'fieldName26', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },				
				{ headerName: this.colName[27], field: 'fieldName27', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" }				
			
			]
		}
		else if(this.NoOfCol != 0 && this.viewWidgetId =="document_total_reference"){
			
			this.columnDefs = [
				{ headerName: this.colName[1], field: 'fieldName1', autoHeight: true, hide: true,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[2], field: 'fieldName2', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[3], field: 'fieldName3', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[4], field: 'fieldName4', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[5], field: 'fieldName5', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName:this.colName[6], field: 'fieldName6', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
				{ headerName:this.colName[7], field: 'fieldName7', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
				{ headerName:this.colName[8], field: 'fieldName8', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
				{ headerName:this.colName[9], field: 'fieldName9', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
				{ headerName:this.colName[10], field: 'fieldName10', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
				{ headerName:this.colName[11], field: 'fieldName11', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
				{ headerName:this.colName[12], field: 'fieldName12', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
				{ headerName:this.colName[13], field: 'fieldName13', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
				{ headerName:this.colName[14], field: 'fieldName14', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
				{ headerName:this.colName[15], field: 'fieldName15', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
				{
					headerName: "ICE Course Counts", 
					children: [
						{ headerName:this.colName[16], field: 'fieldName16', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
						{ headerName:this.colName[17], field: 'fieldName17', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
						{ headerName:this.colName[18], field: 'fieldName18', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
					] 
				},
				{ headerName: this.colName[19], field: 'fieldName19', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[20], field: 'fieldName20', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" }		
			
			]
		}
		else if(this.NoOfCol != 0 && this.viewWidgetId =="component_total_reference"){
			
			this.columnDefs = [
				{ headerName: this.colName[1], field: 'fieldName1', autoHeight: true, hide: true,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[2], field: 'fieldName2', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[3], field: 'fieldName3', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[4], field: 'fieldName4', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[5], field: 'fieldName5', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName:this.colName[6], field: 'fieldName6', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
				{ headerName:this.colName[7], field: 'fieldName7', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
				{ headerName:this.colName[8], field: 'fieldName8', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
				{ headerName:this.colName[9], field: 'fieldName9', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
				{ headerName:this.colName[10], field: 'fieldName10', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
				{ headerName:this.colName[11], field: 'fieldName11', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
				{ headerName:this.colName[12], field: 'fieldName12', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
				{
					headerName: "ICE Course Counts", 
					children: [
						{ headerName:this.colName[13], field: 'fieldName13', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
						{ headerName:this.colName[14], field: 'fieldName14', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
						{ headerName:this.colName[15], field: 'fieldName15', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
					] 
				},
				{ headerName: this.colName[16], field: 'fieldName16', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[17], field: 'fieldName17', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" }		
			
			]
		}

		else if(this.NoOfCol != 0 && this.viewWidgetId =="statements_total_reference"){
			this.columnDefs = [
				{ headerName: this.colName[1], field: 'fieldName1', autoHeight: true, hide: true,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[2], field: 'fieldName2', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[3], field: 'fieldName3', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[4], field: 'fieldName4', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[5], field: 'fieldName5', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{
					headerName: "ICE Course Counts", 
					children: [
						{ headerName:this.colName[6], field: 'fieldName6', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
						{ headerName:this.colName[7], field: 'fieldName7', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
						{ headerName:this.colName[8], field: 'fieldName8', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
						
					] 
				},
				{ headerName:this.colName[9], field: 'fieldName9', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
				{ headerName:this.colName[10], field: 'fieldName10', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
				{ headerName: this.colName[11], field: 'fieldName11', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" }		
			
			]
		}
		else if(this.NoOfCol != 0 && this.viewWidgetId =="facility_total_reference"){
			this.columnDefs = [
				{ headerName: this.colName[1], field: 'fieldName1', autoHeight: true, hide: true,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[2], field: 'fieldName2', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[3], field: 'fieldName3', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[4], field: 'fieldName4', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[5], field: 'fieldName5', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[6], field: 'fieldName6', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[7], field: 'fieldName7', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[8], field: 'fieldName8', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{ headerName: this.colName[9], field: 'fieldName9', autoHeight: true, hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter" },
				{
					headerName: "ICE Course Counts", 
					children: [
						{ headerName:this.colName[10], field: 'fieldName10', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  },
						{ headerName:this.colName[11], field: 'fieldName11', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
						{ headerName:this.colName[12], field: 'fieldName12', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
						
					] 
				},
				{ headerName:this.colName[13], field: 'fieldName13', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
				{ headerName:this.colName[14], field: 'fieldName14', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"   },
				{ headerName:this.colName[15], field: 'fieldName15', autoHeight: true,	hide: false,	cellStyle: { "white-space": "normal" },	cellClass: "cell-wrap-text", suppressSizeToFit : true, filter: "agTextColumnFilter"  }
			
			]
		}

		else if(this.NoOfCol != 0 && this.viewWidgetId !="tags_total_tag_reference_ref_view" && this.viewWidgetId != "system_total_reference"
		&& this.viewWidgetId != "document_total_reference" && this.viewWidgetId != "component_total_reference"
		&& this.viewWidgetId !="statements_total_reference" && this.viewWidgetId !="facility_total_reference"){
			console.log(this.viewWidgetId + " "+ this.fieldName2+" "+this.used)
			this.columnDefs = [];
				// if (this.viewWidgetId =="statements_total_reference" && this.used) {
				// 	this.columnDefs.push(
				// 		{
				// 			headerName: 'Dependencies',
				// 			field: "dependencies",
				// 			width: 50,
				// 			cellStyle: { 'text-align': 'center' },
				// 			cellRendererFramework: StatementsCellRenderComponent
				// 		}
				// 	)

				// }
				
				this.columnDefs.push(
					{
						headerName:  this.colName[1], 
						field: 'fieldName1', 
						autoHeight: true, 
						cellClass: "cell-wrap-text",
						hide: true,
						// suppressToolPanel: true,
						cellStyle: { "white-space": "normal" },
						filter: "agTextColumnFilter", 
					}
				)
				for(let i = 2; i<=this.NoOfCol; i++) {
					let fieldName  = "fieldName" + String(i);
					// if (fieldName == "fieldName3" && this.viewWidgetId =="statements_total_reference" ) {
					// 	this.columnDefs.push(
					// 		{
					// 			headerName:  this.colName[i], 
					// 			field: fieldName, 
					// 			autoHeight: true, 
					// 			cellClass: "cell-wrap-text",
					// 			cellStyle: { "white-space": "normal" },
					// 			filter: "agTextColumnFilter", 
					// 			cellRenderer: function (params: any) {
					// 				var eDiv = document.createElement('div');
					// 				eDiv.innerHTML = params.data.fieldName3;
					// 				return eDiv;
					// 			}
					// 		}
					// 	)

					// }					
					// else {
						this.columnDefs.push(
							{
								headerName: this.colName[i],
								field: fieldName,
								autoHeight: true,
								cellClass: "cell-wrap-text",
								cellStyle: { "white-space": "normal" },
								filter: "agTextColumnFilter",
							}
						)

					// }
					
				}
				
			// this.gridColumnApi.setColumnVisible("fieldName1", false);
		}else if(this.viewWidgetId == 'tags_total_authors'){
			this.columnDefs = [
				{
					headerName: this.colName1, 
					field: 'fieldName1', 
					autoHeight: true, 
					hide: true,
					cellStyle: { "white-space": "normal" },
					cellClass: "cell-wrap-text", 
					suppressSizeToFit : true, 
					filter: "agTextColumnFilter" 
				},
				{
					headerName: "No", 
					field : 'index', 
					autoHeight: true,
					cellStyle: { "white-space": "normal" },
					cellClass: "cell-wrap-text", 
					minWidth: 40, 
					maxWidth : 75, 
					filter: "agTextColumnFilter"
					//suppressSizeToFit : true ,suppressFiltersToolPanel: true
				},
				{
					headerName: this.colName1, 
					field: 'fieldName2', 
					autoHeight: true, 
					cellStyle: { "white-space": "normal" },
					cellClass: "cell-wrap-text", 
					suppressSizeToFit : true, 
					width: 300,  
					minWidth: 200 ,
					filter: "agTextColumnFilter"
				},
				{
					headerName: this.colName2, 
					field: 'fieldName3', 
					autoHeight: true, 
					cellStyle: { "white-space": "normal" },
					cellClass: "cell-wrap-text", 
					minWidth: 200,
					filter: "agTextColumnFilter"
					//suppressFiltersToolPanel: true
				},
			]
		}else if(this.viewWidgetId == 'topic_total_revisions'){
			this.columnDefs = [
				{
					headerName: this.colName1, 
					field: 'fieldName1', 
					autoHeight: true, 
					hide: true,
					cellStyle: { "white-space": "normal" },
					cellClass: "cell-wrap-text", 
					suppressSizeToFit : true,
					filter: "agTextColumnFilter" 
				},
				{
					headerName: "No", 
					field : 'index', 
					autoHeight: true,
					cellStyle: { "white-space": "normal" },
					cellClass: "cell-wrap-text", 
					minWidth: 40, 
					maxWidth : 75, 
					filter: "agTextColumnFilter"
					//suppressSizeToFit : true ,suppressFiltersToolPanel: true
				},		
				{
					headerName: this.colName2, 
					field: 'fieldName2', 
					autoHeight: true, 
					cellStyle: { "white-space": "normal" },
					cellClass: "cell-wrap-text", 
					minWidth: 200,
					filter: "agTextColumnFilter"
					//suppressFiltersToolPanel: true
				}
			]
		}else{
			this.columnDefs = [
				{
					headerName: "No", 
					field : 'index', 
					autoHeight: true,
					cellStyle: { "white-space": "normal" },
					cellClass: "cell-wrap-text", 
					minWidth: 50, 
					maxWidth : 100, 
					suppressSizeToFit : true,
					filter: "agTextColumnFilter"
				},
				{
					headerName: this.colName1, 
					field: 'fieldName1', 
					autoHeight: true, 
					cellClass: "cell-wrap-text", 
					cellStyle: { "white-space": "normal" },
					minWidth: 200,
					filter: "agTextColumnFilter"
					// suppressFiltersToolPanel: true
				}
			]
		}

	}
	
	//remove part of coding from tag description
	getTagDesc(description: any) {
		description = description.replace(/\n/g, "\\n");
		let txt =  /<txt>(.*?)<\/txt>/.exec(description)[1]
		txt = txt.replace(/\\n/g, "\n");
		return txt;
	}

	getStatementCategory(description: any) {
		var eDiv = document.createElement('div');
		eDiv.innerHTML = description;
		description = eDiv.textContent || eDiv.innerText || "";
		return description;
	
	}

	onBtnExcel() {
		this.gridOptions.api.exportDataAsExcel({
			fileName: this.title + ".xlsx",
			processCellCallback: (params) => {
				if (typeof params.value === "string" && /^\d+E[-+]\d+$/.test(params.value)) {
					return `\u200B${params.value}`; // Add a zero-width space to enforce text format
				}
				return params.value;
			}
		});
	}
	
	onBtCopy() {
		this.gridOptions.api.selectAll();
		this.gridOptions.api.copySelectedRowsToClipboard(true);
		let rowCount = this.gridOptions.api.getDisplayedRowCount();
		this.gridOptions.api.deselectAll();
		this.alertService.info("Copied "+ rowCount +" rows to the clipboard");
		this.alertService.timerFunc(2000);
	}
 
	onBtnExcelRef() {
		this.gridOptions1.api.exportDataAsExcel({
			fileName: this.reusableName+" - "+this.referenceType+" - Reference Details.xlsx",
			processCellCallback: (params) => {
				if (typeof params.value === "string" && /^\d+E[-+]\d+$/.test(params.value)) {
					return `\u200B${params.value}`; // Add a zero-width space to enforce text format
				}
				return params.value;
			}
		});
	}

	onBtCopyRef() {
		this.gridOptions1.api.selectAll();		
		this.gridOptions1.api.copySelectedRowsToClipboard(true);
		let rowCountRef = this.gridOptions1.api.getDisplayedRowCount();		
		this.gridOptions1.api.deselectAll();
		this.alertService.info("Copied "+ rowCountRef +" rows to the clipboard");
		this.alertService.timerFunc(2000);
	}

	onBtnExcelTagAttrRef() {
		this.gridOptions2.api.exportDataAsExcel({
			fileName: this.reusableName+" - "+this.referenceType+" - Tag Attribute Reference Details.xlsx"
		});
	}

	onBtCopyTagAttrRef() {
		this.gridOptions2.api.selectAll();
		this.gridOptions2.api.copySelectedRowsToClipboard(true);
		let rowCountTagAttrRef = this.gridOptions2.api.getDisplayedRowCount();	
		this.gridOptions2.api.deselectAll();
		this.alertService.info("Copied "+ rowCountTagAttrRef +" rows to the clipboard");
		this.alertService.timerFunc(2000);
	}
	
}
